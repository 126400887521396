import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ExpandIcon } from 'assets/images/expand-more.svg';

const CustomToggle = React.forwardRef(
  ({ children, onClick, toggleClassName, toggleIcon }, ref) => {
    return (
      <div
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
        className={`app-dropdown-toggle ${toggleClassName}`}
      >
        {children}
        {toggleIcon ? (
          toggleIcon
        ) : (
          <ExpandIcon className="app-dropdown-toggle-icon" />
        )}
      </div>
    );
  }
);

CustomToggle.displayName = 'CustomToggle';

CustomToggle.propTypes = {
  toggleClassName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
  toggleIcon: PropTypes.element,
};

CustomToggle.defaultProps = {
  toggleClassName: '',
  children: '',
  toggleIcon: null,
};

export default CustomToggle;
