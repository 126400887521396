import React from 'react';
import { toast } from 'react-toastify';

import DeleteConfirmModal from 'components/DeleteConfirmModal';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { setSelectedTabInCache } from 'utils/common';
import PropTypes from 'prop-types';
import { toastStyle } from 'utils/helper';

const DeleteMultiBudgets = ({ refetch }) => {
  const {
    refetchBudgets,
    delData,
    showDeleteMultiBudgets,
    hideDeleteMultiBudgetModal,
    removeMultiBudgetsMutuation,
    setSelectedBudget,
    refetchTabs,
    openTabsList,
    setOpenTabsList,
    budgetData,
  } = useHomeScreenContext();

  const handleCancel = () => {
    hideDeleteMultiBudgetModal();
  };

  const getDeleteItemCount = type => {
    return delData?.reduce((i, n) => {
      if (n.type === type) {
        return i + 1;
      }
      return i;
    }, 0);
  };

  const generateDeleteMessage = () => {
    const folderCount = getDeleteItemCount('Folder');
    const budgetCount = getDeleteItemCount('Budget');

    if (folderCount === 0 || budgetCount === 0) {
      if (folderCount === 0) {
        return `Are you sure you want to delete ${budgetCount} files?`;
      } else {
        return `Are you sure you want to delete ${folderCount} folders?`;
      }
    }
    return `Are you sure you want to delete ${budgetCount} ${
      budgetCount === 1 ? 'file' : 'files'
    } and ${folderCount} ${folderCount === 1 ? 'folder' : 'folders'}?`;
  };

  const deleteMessage =
    delData?.length === 1
      ? `Are you sure you want to delete ${delData?.[0]?.full_name}?`
      : generateDeleteMessage();

  const handleDeleteBudget = async () => {
    toast.loading('Deleting budget budgetData ', delData);
    const budgetIds = [];
    const folderIds = [];
    for (let i = 0; i < delData?.length; i++) {
      const id = Number(delData[i]?.id);
      if (delData[i]?.type === 'Folder') {
        folderIds.push(id);
      } else {
        budgetIds?.push(id);
      }
    }
    try {
      await removeMultiBudgetsMutuation.mutateAsync({
        budget_ids: budgetIds,
        folder_ids: folderIds,
      });
      toast.dismiss();
      toast.success(`Budgets deleted successfully`, toastStyle);
      handleCancel();
      refetch();
      refetchTabs();
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        error?.response?.data?.non_field_errors?.[0];
      toast.dismiss();
      toast.error(
        message ||
          `An error occurred while deleting the budgets. Please try again later`,
        toastStyle
      );
    }
  };

  return (
    <DeleteConfirmModal
      showModal={showDeleteMultiBudgets}
      handleOnClose={handleCancel}
      onCancel={handleCancel}
      onConfirm={handleDeleteBudget}
      confirmText={deleteMessage}
      confirmLabel="Delete"
    />
  );
};
DeleteMultiBudgets.propTypes = {
  refetch: PropTypes.func.isRequired, // Adjust the type based on the actual type of refetch
};

export default DeleteMultiBudgets;
