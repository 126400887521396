import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { formatDateTime } from 'utils/helper';
import { propTypes } from 'react-bootstrap/esm/Image';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const BudgetPDF = ({
  selectedBudget,
  budgetType,
  productionDetails,
  swimlaneTable,
  pdfGeneratorRef,
  budgetTotal,
}) => {
  const debug = false;
  const [url, setUrl] = useState('');

  const header = currentPage => {
    return {
      margin: 20,
      columns: [
        {
          table: {
            widths: ['80%', '20%'],
            body: [
              [
                {
                  text: selectedBudget?.name || selectedBudget?.full_name,
                  alignment: 'left',
                  fontSize: '10',
                  height: '30',
                },
                {
                  text: `Page ${currentPage}`,
                  alignment: 'right',
                  fontSize: '10',
                  height: '30',
                },
              ],
            ],
          },
          layout: 'noBorders',
        },
      ],
    };
  };
  const footer = () => {
    return {
      margin: 20,
      columns: [
        {
          table: {
            widths: ['33%', '33%', '34%'],
            body: [
              [
                {
                  text: `${selectedBudget?.company_name} CONFIDENTIAL`,
                  alignment: 'left',
                  fontSize: '9',
                  color: '#222222',
                  height: '30',
                },
                {
                  text: 'Created with CineBudget Pro',
                  alignment: 'center',
                  fontSize: '9',
                  color: '#4D5ABC',
                  height: '30',
                },
                {
                  text: `Prepared by ${
                    selectedBudget?.last_edited_by ||
                    selectedBudget?.created_by_name ||
                    ''
                  }, ${formatDateTime(
                    selectedBudget?.updated_on || selectedBudget?.created_on,
                    'MMMM Do, YYYY'
                  )}`,
                  fontSize: '9',
                  color: '#222222',
                  height: '30',
                  alignment: 'right',
                },
              ],
            ],
          },
          layout: 'noBorders',
        },
      ],
    };
  };

  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [20, 60],
    header,
    footer,
    content: [
      {
        text: `${
          selectedBudget?.name || selectedBudget?.full_name
        } ${budgetType}`,
        alignment: 'center',
        fontSize: '16',
        bold: 'true',
        margin: [0, 15],
      },
      ...(productionDetails || []),
      ...(swimlaneTable || []),
    ],
  };

  const render = () => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    if (debug) {
      pdfDocGenerator.getDataUrl(dataUrl => {
        setUrl(dataUrl);
      });
    }

    pdfGeneratorRef.current = pdfDocGenerator;
  };

  useEffect(() => {
    render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetTotal, productionDetails, swimlaneTable, selectedBudget]);

  return debug ? (
    <div id="#iframeContainer" style={{ width: '100%', height: '30vh' }}>
      {url && (
        <iframe src={url} style={{ width: '100%', height: '100%' }}></iframe>
      )}
    </div>
  ) : (
    <></>
  );
};

BudgetPDF.defaultProps = {
  budgetTotal: 0,
};

BudgetPDF.propTypes = {
  budgetTotal: PropTypes.oneOfType([PropTypes.number, propTypes.string]),
  selectedBudget: PropTypes.object.isRequired,
  budgetType: PropTypes.string.isRequired,
  productionDetails: PropTypes.object.isRequired,
  swimlaneTable: PropTypes.object.isRequired,
  pdfGeneratorRef: PropTypes.object.isRequired,
};
export default BudgetPDF;
