import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Backdrop = ({ children, className }) => {
  return (
    <div className={`app-backdrop-wrapper ${className}`}>
      <div className="app-backdrop" />
      <div className="app-backdrop-content">{children}</div>
    </div>
  );
};

Backdrop.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Backdrop.defaultProps = {
  className: '',
};

export default Backdrop;
