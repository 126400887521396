import { useMutation } from '@tanstack/react-query';
import API_ENDPOINTS from './endpoints';

export const useOnboardingMutation = {
  useVerifyEmailMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.onboarding.verify_email(payload)
    );
  },
  useSignupMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.onboarding.user_signup(payload)
    );
  },
  useResendOTPMutation: () => {
    return useMutation(payload => API_ENDPOINTS.onboarding.resend_otp(payload));
  },
  useVerifyOTPMutation: () => {
    return useMutation(payload => API_ENDPOINTS.onboarding.verify_otp(payload));
  },
  useLoginMutation: () => {
    return useMutation(payload => API_ENDPOINTS.onboarding.user_login(payload));
  },
  useForgotPasswordMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.onboarding.forgot_password(payload)
    );
  },
  useResendForgotPasswordMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.onboarding.forgot_password_resend_otp(payload)
    );
  },
  useVerifyForgotPasswordMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.onboarding.forgot_password_verify_otp(payload)
    );
  },
  useSocialLoginMutation: () => {
    return useMutation(({ provider, payload }) =>
      API_ENDPOINTS.onboarding.social_login(provider, payload)
    );
  },
};

export const useAccountSettingMutation = {
  useChangePasswordMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.account_settings.change_password(payload)
    );
  },
  useLogoutMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.account_settings.user_logout(payload)
    );
  },
  useUserProfileMutation: () => {
    return useMutation(payload => {
      if (payload?.id) {
        return API_ENDPOINTS.account_settings.update_user_profile(payload);
      } else {
        return API_ENDPOINTS.account_settings.create_user_profile(payload);
      }
    });
  },
};

export const useBudgetMutation = {
  useCreateBudgetMutation: () => {
    return useMutation(payload =>
      payload?.id
        ? API_ENDPOINTS.budgets.update_budget(payload)
        : API_ENDPOINTS.budgets.create_budget(payload)
    );
  },
  useCreateFolderMutation: () => {
    return useMutation(payload => API_ENDPOINTS.budgets.create_folder(payload));
  },

  useRenameFolderMutuation: () => {
    return useMutation(payload => API_ENDPOINTS.budgets.update_folder(payload));
  },

  useMoveBudgetMutation: () => {
    return useMutation(payload => API_ENDPOINTS.budgets.move_budget(payload));
  },

  useMoveBudgetFolderMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.budgets.move_budget_folder(payload)
    );
  },

  useDuplicateBudgetMutation: () => {
    return useMutation(payload =>
      payload?.withValues
        ? API_ENDPOINTS.budgets.duplicate_budget_values(payload)
        : API_ENDPOINTS.budgets.duplicate_budget(payload)
    );
  },
  useDeleteBudgetMutation: () => {
    return useMutation(budgetId =>
      API_ENDPOINTS.budgets.delete_budget(budgetId)
    );
  },
  useCreateSwimlaneMutation: () => {
    return useMutation(({ type, swimlane, budgetId, name, selectedItem }) => {
      let payload;
      let endpoint;
      if (type === 'swimlane') {
        payload = {
          type,
          budget: budgetId,
          name,
        };
        endpoint = API_ENDPOINTS.budgets.create_swimlane;
      } else if (type === 'line-item') {
        payload = {
          swimlane,
          name,
          type,
        };
        endpoint = API_ENDPOINTS.budgets.create_line_item;
      } else if (type === 'line-item-children') {
        payload = {
          swimlane,
          parent: selectedItem?.id,
          name,
          type,
        };
        endpoint = API_ENDPOINTS.budgets.create_line_item_children;
      }
      return endpoint(payload);
    });
  },
  useUpdateSwimlaneMutation: () => {
    return useMutation(({ type, name, cost, id }) => {
      let payload;
      let endpoint;
      if (type === 'swimlane') {
        payload = {
          id,
          name,
          cost,
        };
        endpoint = API_ENDPOINTS.budgets.update_swimlane;
      } else if (type === 'line-item') {
        payload = {
          id,
          name,
          cost,
        };
        endpoint = API_ENDPOINTS.budgets.update_line_item;
      } else if (type === 'line-item-children') {
        payload = {
          id,
          name,
          cost,
        };
        endpoint = API_ENDPOINTS.budgets.update_line_item_children;
      }
      return endpoint(payload);
    });
  },
  useDeleteSwimlaneMutation: () => {
    return useMutation(({ type, id }) => {
      let endpoint;
      if (type === 'swimlane') {
        endpoint = API_ENDPOINTS.budgets.delete_swimlane;
      } else if (type === 'line-item') {
        endpoint = API_ENDPOINTS.budgets.delete_line_item;
      } else if (type === 'line-item-children') {
        endpoint = API_ENDPOINTS.budgets.delete_line_item_children;
      }
      return endpoint(id);
    });
  },
  useAddSubcategoryMutation: () => {
    return useMutation(({ type, id }) => {
      let endpoint;
      if (type === 'swimlane') {
        endpoint = API_ENDPOINTS.budgets.add_sub_category_swimlane;
      } else if (type === 'line-item') {
        endpoint = API_ENDPOINTS.budgets.add_sub_category_line_item;
      } else if (type === 'line-item-children') {
        endpoint = API_ENDPOINTS.budgets.add_sub_category_line_item;
      }
      return endpoint(id);
    });
  },
  useCreateSwimlaneDetailsMutation: () => {
    return useMutation(({ type, selectedItem, details }) => {
      let payload;
      let endpoint;
      if (type === 'swimlane') {
        payload = {
          ...details,
          swimlane: selectedItem.id,
        };
        endpoint = API_ENDPOINTS.budgets.create_swimlane_detail_sheet;
      } else if (type === 'line-item') {
        payload = {
          ...details,
          line_item: selectedItem.id,
        };
        endpoint = API_ENDPOINTS.budgets.create_line_item_detailed_sheet;
      } else if (type === 'line-item-children') {
        payload = {
          ...details,
          line_item: selectedItem.id,
        };
        endpoint =
          API_ENDPOINTS.budgets.create_line_item_children_detailed_sheet;
      }

      return endpoint(payload);
    });
  },
  useUpdateSwimlaneDetailsMutation: () => {
    return useMutation(({ type, selectedItem, details }) => {
      let payload;
      let endpoint;
      if (type === 'swimlane') {
        payload = {
          ...details,
          swimlane: selectedItem.id,
        };
        endpoint = API_ENDPOINTS.budgets.update_swimlane_detail_sheet;
      } else if (type === 'line-item') {
        payload = {
          ...details,
          line_item: selectedItem.id,
        };
        endpoint = API_ENDPOINTS.budgets.update_line_item_detailed_sheet;
      } else if (type === 'line-item-children') {
        payload = {
          ...details,
          line_item: selectedItem.id,
        };
        endpoint =
          API_ENDPOINTS.budgets.update_line_item_children_detailed_sheet;
      }

      return endpoint(payload);
    });
  },
  useDeleteSwimlaneDetailsMutation: () => {
    return useMutation(({ type, id }) => {
      let endpoint;
      if (type === 'swimlane') {
        endpoint = API_ENDPOINTS.budgets.delete_swimlane_details_sheet;
      } else if (type === 'line-item') {
        endpoint = API_ENDPOINTS.budgets.delete_line_item_details_sheet;
      } else if (type === 'line-item-children') {
        endpoint =
          API_ENDPOINTS.budgets.delete_line_item_children_details_sheet;
      }

      return endpoint(id);
    });
  },
  useShareBudgetMutation: () => {
    return useMutation(payload => {
      return API_ENDPOINTS.budgets.share_budget(payload);
    });
  },
  useRemoveBudgetAccessMutation: () => {
    return useMutation(payload => {
      return API_ENDPOINTS.budgets.remove_budget_access(payload);
    });
  },
  useRemoveMultiBudgetsMutuation: () => {
    return useMutation(payload => {
      return API_ENDPOINTS.budgets.delete_multi_budgets(payload);
    });
  },
  useRemoveFolderMutuation: () => {
    return useMutation(payload => {
      return API_ENDPOINTS.budgets.delete_folder(payload);
    });
  },
  useBudgetDownloadMutation: () => {
    return useMutation(payload => {
      return API_ENDPOINTS.budgets.download_budget(payload);
    });
  },
};

export const useTabsMutuation = {
  useCreateTabMutation: () => {
    return useMutation(payload => API_ENDPOINTS.tabs.create_tabs(payload));
  },
  useMakeActiveTabMutation: () => {
    return useMutation(payload => API_ENDPOINTS.tabs.make_active_tab(payload));
  },
  useCloseTabMutation: () => {
    return useMutation(tabId => API_ENDPOINTS.tabs.close_tab(tabId));
  },
};

export const useUserActivityMutation = {
  usePostUserActivityMutation: () => {
    return useMutation(payload =>
      API_ENDPOINTS.user_activity.post_user_activity(payload)
    );
  },
};

export const usePostReorderSwimlane = {
  usePostReorderSwimlane: () => {
    return useMutation(payload =>
      API_ENDPOINTS.reorder_swimlane.reorder_swimlane(payload)
    );
  },
};

export const usePostReorderLineItem = {
  usePostReorderLineItem: () => {
    return useMutation(payload =>
      API_ENDPOINTS.reorder_line_item.reorder_line_item(payload)
    );
  },
};
