import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import Button from 'components/Button';
import { useAppContext } from 'contexts/AppContext';

import './styles.scss';

const LogoutModal = ({ showLogOutModal, hideLogOutModal }) => {
  const { handleLogout } = useAppContext();

  return (
    <Modal
      onHide={hideLogOutModal}
      show={showLogOutModal}
      size="sm"
      className="logout-modal"
      bodyClass="logout-modal__body"
      centered={true}
    >
      <h1 className="logout-modal__body__header">Log Out</h1>
      <p className="logout-modal__body__text">
        Are you sure you want to log out?
      </p>
      <div className="logout-modal__body__action">
        <Button
          label="Cancel"
          className="change-password__form__action__cancel"
          variant="red-outlined"
          onClick={hideLogOutModal}
        />
        <Button
          label="Confirm"
          className="change-password-form__verify-button"
          type="button"
          onClick={handleLogout}
        />
      </div>
    </Modal>
  );
};

LogoutModal.propTypes = {
  showLogOutModal: PropTypes.bool.isRequired,
  hideLogOutModal: PropTypes.func.isRequired,
};

export default LogoutModal;
