import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import OTPInput, { ResendOTP } from 'otp-input-react';

import Button from 'components/Button';
import { ReactComponent as ErrorIcon } from 'assets/images/error-outline.svg';
import { ReactComponent as SuccessIcon } from 'assets/images/check-circle-outline.svg';

import './styles.scss';

const FormOTP = React.forwardRef(
  (
    { label, otp, onChange, labelClassName, error, success, handleResendOtp },
    ref
  ) => {
    const renderButton = buttonProps => {
      return (
        <Button
          {...buttonProps}
          variant={buttonProps?.remainingTime !== 0 ? 'grey' : 'primary'}
          style={{ width: '100%', maxWidth: '120px', borderRadius: '100px' }}
          label={
            buttonProps.remainingTime !== 0
              ? `${buttonProps.remainingTime}`
              : 'Resend'
          }
          disabled={Boolean(success) || buttonProps?.remainingTime > 0}
        />
      );
    };
    const renderTime = () => React.Fragment;

    return (
      <Form.Group className="form-otp-group">
        <Form.Label
          className={`d-flex align-item-start w-100 form-otp-group__label ${labelClassName}`}
        >
          {label}
        </Form.Label>
        <div className="form-otp-group__input__container d-flex justify-content-between align-items-center">
          <OTPInput
            className="form-otp-group__input"
            value={otp}
            onChange={onChange}
            ref={ref}
            autoFocus
            OTPLength={4}
            otpType="number"
            inputClassName="form-otp-group__input__field"
            // disabled={Boolean(success)}
          />
          <ResendOTP
            renderButton={renderButton}
            renderTime={renderTime}
            className="form-otp-group__resend"
            onResendClick={handleResendOtp}
            maxTime={300}
          />
        </div>
        {error && (
          <div className="invalid-feedback">
            <span className="error-icon">
              <ErrorIcon />
            </span>
            {error}
          </div>
        )}
        {success && (
          <div className="valid-feedback">
            <span className="error-icon">
              <SuccessIcon />
            </span>
            {success}
          </div>
        )}
      </Form.Group>
    );
  }
);

FormOTP.displayName = 'FormOTP';

FormOTP.propTypes = {
  label: PropTypes.string,
  otp: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  labelClassName: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  handleResendOtp: PropTypes.func.isRequired,
};

FormOTP.defaultProps = {
  label: 'Verification Code',
  otp: null,
  labelClassName: '',
  error: '',
  success: '',
};

export default FormOTP;
