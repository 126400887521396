import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import currencyToSymbolMap from 'currency-symbol-map/map';

import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';

import './styles.scss';
import {
  formatDateTime,
  intervalToDuration,
  isArray,
  toastStyle,
} from 'utils/helper';
import { shootingLocations } from 'utils/constants';

const BudgetSettings = () => {
  const {
    createBudgetMutation,
    refetchBudgets,
    showBudgetSettings,
    hideBudgetSettingsModal,
    selectedBudget,
    selectTab,
    refetchTabs,
    clearBudgetRename,
    budgetData,
    refetchBudgetId,
  } = useHomeScreenContext();
  const [currencyList, setCurrencyList] = useState([]);
  const budgetSettingsForm = useRef();
  const defaultValues = {
    name: selectedBudget?.name || '',
    company_name: selectedBudget?.company_name,
    shooting_locations:
      selectedBudget?.shooting_locations?.map(location => ({
        value: location,
        label: location,
      })) || '',
    primary_currency: selectedBudget?.primary_currency
      ? {
          value: selectedBudget?.primary_currency,
          label: `${selectedBudget?.primary_currency}(${
            currencyToSymbolMap[selectedBudget?.primary_currency]
          })`,
        }
      : undefined,
    shooting_days: selectedBudget?.shooting_days || '',
    principal_photography: selectedBudget?.principal_photography || '',
    wrap_principal: selectedBudget?.wrap_principal || '',
    script_pages: selectedBudget?.script_pages || '',
    script_version: selectedBudget?.script_pages || '',
    producers: selectedBudget?.producers?.join(', ') || '',
    director: selectedBudget?.director || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    setCurrencyList(
      Object.keys(currencyToSymbolMap)?.map(currencyCode => ({
        label: `${currencyCode}(${currencyToSymbolMap[currencyCode]})`,
        value: currencyCode,
      }))
    );
  }, []);

  const handleSubmitClick = e => {
    e.preventDefault();

    budgetSettingsForm?.current?.click();
  };

  const preparePayload = data =>
    Object.keys(data)?.reduce((acc, field) => {
      if (data[field]) {
        let value = data[field];
        if (field === 'producers') {
          value = value?.split(/[,\n]/)?.map(v => v?.trim());
        } else if (field === 'shooting_locations') {
          value = isArray(value) ? value?.map(v => v?.value) : [value.value];
        } else if (field === 'primary_currency') {
          value = value?.value;
        }
        acc[field] = value;
      }
      return acc;
    }, {});

  const handleBudgetSettings = () =>
    handleSubmit(async data => {
      const payload = preparePayload(data) || {};

      if (!Object.keys(payload)?.length) {
        return false;
      }

      try {
        const response = await createBudgetMutation.mutateAsync({
          ...payload,
          id: selectedBudget?.budget,
        });
        const newBudget = response?.data;
        refetchBudgets?.();
        refetchBudgetId?.();
        refetchTabs?.();
        toast.success(
          `Budget ${data?.name} ${
            selectedBudget?.budget ? 'updated' : 'created'
          } successfully`,
          toastStyle
        );
        reset();
        handleCancel();
      } catch (error) {
        const message =
          error?.response?.data?.shooting_locations?.[0] ||
          error?.response?.data?.name?.[0] ||
          error?.response?.data?.non_field_errors?.[0];
        toast.error(
          message ||
            `An error occurred while ${
              selectedBudget?.budget ? 'updating' : 'creating'
            } budget. Please try again later`,
          toastStyle
        );
      }
    });

  const handleCancel = () => {
    hideBudgetSettingsModal();
    clearBudgetRename();
    reset();
  };

  const modalTitle = () => {
    return (
      <>
        <div className="budget-settings__title">Budget Settings</div>
        <div className="budget-settings__sub-title">
          {budgetData?.name}
          <div className="created-date">
            {formatDateTime(budgetData?.created_on, 'MMMM Do, YYYY')}
          </div>
        </div>
      </>
    );
  };

  const modalFooter = () => {
    return (
      <>
        <div className="budget-settings__body__action">
          <Button
            label="Cancel"
            type="reset"
            className="budget-settings__action__cancel"
            variant="red-outlined"
            onClick={handleCancel}
          />
          <Button
            label="Update"
            className="budget-settings__action__confirm"
            type="button"
            disabled={!isValid}
            onClick={handleSubmitClick}
          />
        </div>
        <div className="budget-settings__body__modified-info">
          <div className="budget-duration">
            Total Time Spent Editing:{' '}
            <span className="duration">
              {intervalToDuration(
                budgetData?.created_on,
                budgetData?.updated_on
              )}
            </span>
          </div>
          <div className="budget-modified">
            Last updated by{' '}
            <span className="budget-modified-by">{lastEditedBy}</span>
            {/* ,&nbsp; */}
            {formatDateTime(lastEditDateTime)}.
          </div>
        </div>
      </>
    );
  };

  const lastEditedBy =
    budgetData?.last_edited_by || budgetData?.created_by_name;
  const lastEditDateTime = budgetData?.updated_on || budgetData?.created_on;

  return (
    <Modal
      onHide={handleCancel}
      show={showBudgetSettings}
      size="sm"
      className="budget-settings"
      bodyClass="budget-settings__body"
      centered={true}
      title={modalTitle()}
      footer={modalFooter()}
      scrollable
    >
      <Form
        className="budget-settings__body__form"
        onSubmit={handleBudgetSettings()}
      >
        <FormInput
          label="Company Name"
          type="text"
          placeholder="Please enter the company name"
          error={errors?.company_name?.message}
          {...register('company_name', { required: false })}
        />
        <FormSelect
          label="Shooting Location(s)"
          error={errors?.name?.message}
          options={shootingLocations}
          placeholder="Los Angeles, CA"
          name="shooting_locations"
          rules={{
            required: false,
          }}
          control={control}
          maxMenuHeight={150}
          closeMenuOnSelect={false}
          isSearchable
          isMulti
          creatable
        />
        <FormInput
          label="Number of Shooting Days"
          type="text"
          placeholder=""
          error={errors?.shooting_days?.message}
          {...register('shooting_days', { required: false })}
        />
        <FormSelect
          label="Primary Currency"
          error={errors?.primary_currency?.message}
          options={currencyList}
          placeholder="USD ($)"
          name="primary_currency"
          rules={{
            required: false,
          }}
          control={control}
          maxMenuHeight={150}
          isSearchable
        />
        <FormInput
          label="Director"
          type="text"
          placeholder="Director 1, Director 2"
          error={errors?.shooting_locations?.message}
          {...register('director', { required: false })}
        />
        <FormInput
          label="Producer(s)"
          type="textarea"
          placeholder="Producer 1, Producer 2"
          error={errors?.producers?.message}
          {...register('producers', { required: false })}
        />
        <FormInput
          label="Script Pages"
          type="text"
          placeholder=""
          error={errors?.script_pages?.message}
          {...register('script_pages', { required: false })}
        />
        <FormInput
          label="Script Version"
          type="text"
          placeholder=""
          error={errors?.shooting_locations?.message}
          {...register('script_version', { required: false })}
        />
        <FormInput
          label="Principal Photography Start Date"
          type="date"
          placeholder=""
          error={errors?.principal_photography?.message}
          {...register('principal_photography', { required: false })}
        />
        <FormInput
          // label="Wrap Principal"
          label="Estimated Wrap Date"
          type="date"
          placeholder=""
          error={errors?.wrap_principal?.message}
          {...register('wrap_principal', { required: false })}
        />
        <Button
          label="Update"
          style={{ height: 0, width: 0, opacity: 0, overflow: 'hidden' }}
          type="submit"
          ref={budgetSettingsForm}
        />
      </Form>
    </Modal>
  );
};

export default BudgetSettings;
