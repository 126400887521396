import React from 'react';
import { toast } from 'react-toastify';

import DeleteConfirmModal from 'components/DeleteConfirmModal';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { setSelectedTabInCache } from 'utils/common';
import PropTypes from 'prop-types';
import { toastStyle } from 'utils/helper';

const DeleteFolder = ({ refetch }) => {
  const {
    refetchBudgets,
    folderData,
    showDeleteFolder,
    hideDeleteFolderModal,
    removeMultiBudgetsMutuation,
  } = useHomeScreenContext();

  const handleCancel = () => {
    hideDeleteFolderModal();
  };

  const handleDeleteBudget = async () => {
    toast.loading('Deleting folder data ');
    try {
      const payload = {
        budget_ids: [],
        folder_ids: [folderData?.id],
      };
      await removeMultiBudgetsMutuation.mutateAsync(payload);
      toast.dismiss();
      toast.success(`Folder deleted successfully`, toastStyle);
      handleCancel();
      refetch();
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        error?.response?.data?.non_field_errors?.[0];
      toast.dismiss();
      toast.error(
        message ||
          `An error occurred while deleting the budgets. Please try again later`,
        toastStyle
      );
    }
  };

  return (
    <DeleteConfirmModal
      showModal={showDeleteFolder}
      handleOnClose={handleCancel}
      onCancel={handleCancel}
      onConfirm={handleDeleteBudget}
      confirmText={`Are you sure you want to delete ?`}
      confirmLabel="Delete"
    />
  );
};
DeleteFolder.propTypes = {
  refetch: PropTypes.func.isRequired, // Adjust the type based on the actual type of refetch
};

export default DeleteFolder;
