/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ReactComponent as CopyLink } from 'assets/images/CopyLink.svg';
import './styles.scss';

const Modal = ({
  onHide,
  show,
  children,
  className,
  bodyClass,
  title,
  footer,
  size,
  closeButton,
  copyUrl,
  handleCopyUrl,
  ...rest
}) => {
  return (
    <BootstrapModal
      size={size}
      show={show}
      onHide={onHide}
      className={className}
      {...rest}
    >
      {title && (
        <BootstrapModal.Header closeButton={false}>
          <BootstrapModal.Title>{title}</BootstrapModal.Title>
          <div className="header-icons">
            {copyUrl && (
              <CopyLink className="copy-link-icon" onClick={handleCopyUrl} />
            )}
            {closeButton && (
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={onHide}
              />
            )}
          </div>
        </BootstrapModal.Header>
      )}
      <BootstrapModal.Body className={bodyClass}>
        {children}
      </BootstrapModal.Body>
      {footer && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
};

Modal.defaultProps = {
  className: '',
  title: '',
  footer: '',
  bodyClass: '',
  size: 'lg',
  closeButton: true,
  copyUrl: false,
  handleCopyUrl: undefined,
};

Modal.propTypes = {
  className: PropTypes.string,
  bodyClass: PropTypes.string,
  show: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.oneOf(['sm', 'lg', 'xl']),
  onHide: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  closeButton: PropTypes.bool,
  copyUrl: PropTypes.bool,
  handleCopyUrl: PropTypes.func,
};

export default Modal;
