import React from 'react';
import moment from 'moment-timezone';

import APP_CONFIG from 'config/app-config';
import {
  deleteCookies,
  fixNumber,
  formatCurrency,
  formatNumber,
  getCurrencySymbol,
  sortArray,
} from './helper';
import { ReactComponent as CommentIcon } from 'assets/images/comment.svg';
import ALL_ROUTES from 'config/routes';

export const getTokenInfo = () => {
  try {
    const savedSession = JSON.parse(
      localStorage.getItem(APP_CONFIG.sessionVariables.AUTH_TOKEN) || null
    );
    return savedSession;
  } catch (e) {
    return '';
  }
};
export const isTokenValid = tokenInfo => {
  tokenInfo ||= getTokenInfo();
  if (
    tokenInfo?.expiryTime &&
    moment(tokenInfo?.expiryTime).diff(moment(new Date()), 'minutes') <= 0
  ) {
    return false;
  }

  return true;
};

export const getAuthToken = () => {
  const savedSession = getTokenInfo();
  return isTokenValid(savedSession) && savedSession?.token;
};

export const userTokenExists = () => {
  return Boolean(getAuthToken());
};

export const checkAndSetUserLoggedIn = setIsUserLoggedIn => {
  const userTokenFound = userTokenExists();
  if (userTokenFound) {
    setIsUserLoggedIn(true);
  }
};

export const getUserData = () => {
  try {
    return JSON.parse(
      localStorage.getItem(APP_CONFIG.sessionVariables.USER_INFO || null)
    );
  } catch (e) {
    return '';
  }
};

/**
 *
 * @param {Boolean} rememberMe
 * @param {String} email
 * @param {String} password
 */
export const handleRememberMeSetting = (rememberMe, email) => {
  if (rememberMe) {
    localStorage.setItem('email', email);
    localStorage.setItem('remember_me', true);
  } else {
    localStorage.removeItem('email');
    localStorage.removeItem('remember_me');
  }
};

export const handleLoginSuccess = (token, userData, navigate) => {
  let expiryTime;
  const getDateTime = date => moment.utc(date).tz(moment.tz.guess());
  if (localStorage.getItem('remember_me')) {
    expiryTime = getDateTime(new Date()).add(90, 'd');
  } else {
    expiryTime = getDateTime(new Date()).add(4, 'h');
  }

  localStorage.setItem(
    APP_CONFIG.sessionVariables.AUTH_TOKEN,
    JSON.stringify({ token, expiryTime })
  );
  localStorage.setItem(
    APP_CONFIG.sessionVariables.USER_INFO,
    JSON.stringify({
      ...userData,
    })
  );

  navigate(ALL_ROUTES.HOME_SCREEN);
};

export const carryOutLogout = setIsUserLoggedIn => {
  localStorage.removeItem(APP_CONFIG.sessionVariables.USER_INFO);
  localStorage.removeItem(APP_CONFIG.sessionVariables.AUTH_TOKEN);
  sessionStorage.clear();
  deleteCookies();
  setIsUserLoggedIn(false);
};

export const getSelectedTabFromCache = () => {
  try {
    return JSON.parse(
      localStorage.getItem(APP_CONFIG.sessionVariables.SELECTED_TAB) || null
    );
  } catch (e) {
    return '';
  }
};

export const setSelectedTabInCache = selectedTab => {
  try {
    return localStorage.setItem(
      APP_CONFIG.sessionVariables.SELECTED_TAB,
      JSON.stringify(selectedTab)
    );
  } catch (e) {
    return '';
  }
};
export const prepareDropDownOptions = (options, labelKey, valueKey) => {
  return (
    options?.map(item => ({
      label: item[labelKey],
      value: item[valueKey]?.toString(),
    })) || []
  );
};

export const getDetailsSheet = (item, type) => {
  return type === 'swimlane'
    ? item?.swimlane_detail_sheets
    : item?.swimlane_item_detail_sheets;
};

export const deleteSwimlaneDetailsItem = (
  queryClient,
  selectedItem,
  selectedSwimlane,
  swimlanePayload
) => {
  const swimlaneData = queryClient.getQueryData([
    'get-swimlanes-in-budget',
    swimlanePayload,
  ]);
  const swimlaneItems = swimlaneData?.data || [];
  const selectedSwimlaneItem = {
    ...(swimlaneItems?.find(
      swimlaneItem => swimlaneItem?.id === selectedSwimlane?.id
    ) || {}),
  };
  const swimlaneDetails =
    getDetailsSheet(selectedSwimlaneItem, swimlanePayload.type) || [];
  selectedSwimlaneItem[
    swimlanePayload.type === 'swimlane'
      ? 'swimlane_detail_sheets'
      : 'swimlane_item_detail_sheets'
  ] = swimlaneDetails.filter(row => row?.id !== selectedItem?.id) || [];

  queryClient.setQueryData(['get-swimlanes-in-budget', swimlanePayload], {
    ...swimlaneData,
    data:
      swimlaneItems?.map(swimlaneItem =>
        swimlaneItem?.id === selectedSwimlane?.id
          ? selectedSwimlaneItem
          : swimlaneItem
      ) || [],
  });
};

export const compareFormValues = (newValues, defaultValues) => {
  return Object.keys(newValues)?.some(field => {
    const changedValue =
      typeof newValues?.[field] === 'object'
        ? newValues?.[field]?.value || ''
        : newValues?.[field];

    return changedValue !== defaultValues?.[field];
  });
};

export const prepareProfileInfo = (userProfile, userData) => ({
  user_type: (userProfile?.user_type || userData?.user_type || '')?.toString(),
  name: (userProfile?.name || userData?.name)?.trim(),
});

export const prepareContactInfo = (userProfile, userData) => ({
  phone_number: userProfile?.userprofile?.phone_number || '',
  email: userData?.email,
});

export const prepareAddressInfo = userProfile => ({
  address_line_1: userProfile?.useraddress?.address_line_1 || '',
  address_line_2: userProfile?.useraddress?.address_line_2 || '',
  country: userProfile?.useraddress?.country || '',
  state: userProfile?.useraddress?.state || '',
  city: userProfile?.useraddress?.city || '',
  zip_code: userProfile?.useraddress?.zip_code || '',
});

export const calculateDetailsTotal = (newDetails, setDetailsTotal) => {
  const itemsTotal = newDetails?.reduce((total, item) => {
    total += isNaN(item?.item_total) ? 0 : parseFloat(item?.item_total) || 0;
    return total;
  }, 0);

  typeof setDetailsTotal === 'function' &&
    setDetailsTotal(parseFloat(itemsTotal?.toFixed(2)));

  return parseFloat(itemsTotal?.toFixed(2));
};

export const handlePartialDataUpdate = (
  existingRows,
  updatedRows,
  selectedSwimlane,
  type
) => {
  const uniqueRows =
    updatedRows?.reduce((acc, row) => {
      return { ...acc, [row?.row_id ? row?.row_id : row?.id]: row };
    }, {}) || {};

  let index = 0;
  if (existingRows?.length) {
    index = Math.max(...existingRows.map(row => row.row_order));
  } else {
    existingRows = [
      {
        row_order: (updatedRows?.length || 0) + 1,
        row_id: `row-${type}-${selectedSwimlane?.id}-${
          updatedRows?.length + 1
        }`,
      },
    ];
  }
  index++;

  let allRows = existingRows.map(item => {
    const row = uniqueRows[item?.row_id] || uniqueRows[item?.id];
    if (row) {
      delete uniqueRows[item?.row_id];
      delete uniqueRows[item?.id];
    }

    return row
      ? {
          ...row,
          row_id: item?.row_id,
          row_order: item?.row_order,
        }
      : item;
  });

  allRows = [
    ...allRows,
    ...sortArray(Object.values(uniqueRows), 'id').map(row => ({
      ...row,
      row_id: `row-${type}-${selectedSwimlane?.id}-${index}`,
      row_order: index++,
    })),
  ];

  return allRows;
};

export const formatTotal = currency => value => {
  value = fixNumber(value);

  const formatted =
    value !== undefined && value !== null && value !== ''
      ? formatCurrency(value, currency)
      : getCurrencySymbol(currency);
  return formatted;
};

export const formatMultiplier = value => {
  value = fixNumber(value, false);
  return value !== undefined &&
    value !== null &&
    value >= 0 &&
    !Number.isNaN(value)
    ? parseFloat(value)
    : value;
};

export const formatRate = currency => value => {
  value = fixNumber(value);
  const formatted =
    (value && !Number.isNaN(value)
      ? formatNumber(value, currency)
      : parseFloat(value || 0)) || '';
  return !/^0(\.00)?$/.test(value) && /^\D?0\.00$/.test(formatted)
    ? ''
    : formatted;
};

export const getColumnConf = (selectedBudget, unitTypes, toggleColumn) => {
  return [
    {
      label: 'Item',
      key: 'item',
      width: '216px',
      type: 'text',
      placeholder: 'Enter Item...',
      required: true,
    },
    {
      label: 'Quantity',
      key: 'quantity',
      dataType: 'decimal',
      width: '96px',
      type: 'text',
      placeholder: '1',
      required: false,
      formatValue: formatMultiplier,
      pluralize: {
        key: 'unit',
        valueKey: 'quantity',
      },
      defaultValue: '1',
      allowNegative: false,
    },
    {
      label: 'Unit',
      key: 'unit',
      width: '96px',
      type: 'select',
      placeholder: 'Unit',
      options: unitTypes,
      required: false,
      pluralize: {
        key: 'unit',
        valueKey: 'quantity',
      },
    },
    {
      label: 'Rate',
      key: 'rate',
      dataType: 'decimal',
      width: '96px',
      type: 'text',
      placeholder: '',
      prefix: getCurrencySymbol(selectedBudget?.primary_currency),
      required: true,
      // formatValue: formatMultiplier,
      formatValue: formatRate(selectedBudget?.primary_currency),
    },
    {
      label: 'Multiplier',
      key: 'multiplier',
      dataType: 'decimal',
      width: '96px',
      type: 'text',
      placeholder: '1',
      required: false,
      formatValue: formatMultiplier,
      pluralize: {
        key: 'multiplier_unit',
        valueKey: 'multiplier',
      },
      defaultValue: '1',
      allowNegative: false,
    },
    {
      label: 'Multiplier Unit',
      key: 'multiplier_unit',
      width: '96px',
      type: 'select',
      placeholder: 'Unit',
      options: unitTypes,
      required: false,
      pluralize: {
        key: 'multiplier_unit',
        valueKey: 'multiplier',
      },
    },
    {
      label: 'Item Total',
      key: 'item_total',
      type: 'showValue',
      dataType: 'decimal',
      width: '96px',
      formatValue: formatTotal(selectedBudget?.primary_currency),
      required: false,
    },
    {
      label: 'Comments',
      key: 'comment',
      width: '350px',
      type: 'text',
      placeholder: 'Notes....',
      icon: <CommentIcon />,
      onClick: toggleColumn?.('comment'),
      required: false,
    },
  ];
};

export const calculateItemTotal = itemRow => {
  const quantity = fixNumber(itemRow.quantity, false);
  const rate = fixNumber(itemRow.rate);
  const multiplier = fixNumber(itemRow.multiplier, false);

  return (
    Object.hasOwn(itemRow, 'rate') &&
    (Number.isNaN(quantity) ? 1 : parseFloat(quantity >= 0 ? quantity : 1)) *
      (Number.isNaN(rate) ? 0 : parseFloat(rate || 0)) *
      (Number.isNaN(multiplier)
        ? 1
        : parseFloat(multiplier >= 0 ? multiplier : 1))
  );
};
