import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, Form } from 'react-bootstrap';

import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import './styles.scss';

const SearchInput = React.forwardRef(({ placeholder, ...rest }, ref) => {
  return (
    <InputGroup className="search-input-wrapper">
      <InputGroup.Text className="search-icon">
        <SearchIcon />
      </InputGroup.Text>
      <Form.Control
        aria-label={placeholder}
        className="search-input"
        placeholder={placeholder}
        {...rest}
      />
    </InputGroup>
  );
});

SearchInput.displayName = 'SearchInput';

SearchInput.propTypes = {
  placeholder: PropTypes.string,
};

SearchInput.defaultProps = {
  placeholder: 'Search budgets...',
};

export default SearchInput;
