import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as EditIcon } from 'assets/images/edit-icon.svg';
import { ReactComponent as SaveIcon } from 'assets/images/check-icon.svg';

import './styles.scss';

const Card = ({
  className,
  children,
  title,
  editable,
  onEditClick,
  editMode,
  onSave,
}) => {
  return (
    <div className={`card-container ${className}`}>
      {title && (
        <div className="card-container__header">
          <h2 className="card-container__header__title">{title}</h2>
          {editable &&
            (editMode ? (
              <span className="edit-icon" onClick={onSave}>
                <SaveIcon />
              </span>
            ) : (
              <span className="edit-icon" onClick={onEditClick}>
                <EditIcon />
              </span>
            ))}
        </div>
      )}
      {children}
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  editable: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  editMode: PropTypes.bool,
  onSave: PropTypes.func,
  onEditClick: PropTypes.func,
};

Card.defaultProps = {
  className: '',
  editable: false,
  title: '',
  onEditClick: () => null,
  editMode: false,
  onSave: () => null,
};

export default Card;
