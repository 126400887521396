import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';

import FormInput from 'components/FormInput';
import Button from 'components/Button';
import FormPasswordInput from 'components/FormPasswordInput';
import { ReactComponent as ArrowBack } from 'assets/images/arrow-back.svg';
import FormOTP from 'components/FormOTP';
import ALL_ROUTES from 'config/routes';
import { useAuthContext } from 'layouts/AuthLayout/contexts/AuthContext';
import { validatePassword, validatePasswordMatch } from 'utils/validator';
import { handleRememberMeSetting } from 'utils/common';

import './styles.scss';
import { toastStyle } from 'utils/helper';

const ForgotPassword = () => {
  const {
    forgotPasswordMutation,
    resendForgotPasswordMutation,
    verifyForgotPasswordMutation,
  } = useAuthContext();
  const navigate = useNavigate();
  const [verifyOtp, setVerifyOtp] = useState();
  const [otpValue, setOtpValue] = useState(null);
  const [otpVerified, setOtpVerified] = useState(null);
  const {
    register: emailFormRegister,
    handleSubmit: emailFormSubmit,
    formState: { errors: emailFormErrors },
    getValues: emailFormGetValues,
  } = useForm();

  const {
    register: otpFormRegister,
    handleSubmit: otpFormSubmit,
    formState: { errors: otpFormErrors },
    reset: otpFormReset,
    getValues: otpFormGetValues,
    trigger: otpFormTrigger,
    watch: otpFormWatch,
  } = useForm();
  const password = otpFormWatch('password');

  useEffect(() => {
    if (otpFormGetValues('confirm_password')) {
      otpFormTrigger('confirm_password');
    }
  }, [password, otpFormGetValues, otpFormTrigger]);

  const handleOtpChange = otp => {
    setOtpValue(otp);
    setOtpVerified(null);
  };

  const handleEmailFormSubmit = () =>
    emailFormSubmit(async data => {
      try {
        await forgotPasswordMutation.mutateAsync({
          ...data,
        });
        setVerifyOtp(true);
        toast.success('OTP has been sent to your email address', toastStyle);
      } catch (error) {
        const errorMessage = error?.response?.data?.non_field_errors?.[0];
        toast.error(
          errorMessage ||
            'An error occurred while verifying email. Please try again later',
          toastStyle
        );
      }
    });

  const resendOtp = async () => {
    const data = { email: emailFormGetValues('email') };
    try {
      await resendForgotPasswordMutation.mutateAsync({
        ...data,
      });
      setVerifyOtp(true);
      toast.success('OTP has been sent to email address.', toastStyle);
    } catch (error) {
      toast.error(
        'An error occurred while resending the OTP. Please try again later',
        toastStyle
      );
    }
  };

  const handleOtpFormSubmit = () =>
    otpFormSubmit(async data => {
      if (otpValue?.length < 4) {
        return setOtpVerified({
          valid: false,
          error: 'Please enter valid OTP',
        });
      }

      try {
        await verifyForgotPasswordMutation.mutateAsync({
          ...data,
          otp: otpValue,
          email: emailFormGetValues('email'),
        });
        resetOtpForm();
        handleRememberMeSetting();
        navigate(ALL_ROUTES.AUTH_CHILDREN.LOGIN);
        toast.success('Password has been reset successfully.', toastStyle);
      } catch (error) {
        const message = error?.response?.data?.message?.non_field_errors?.[0];
        if (message === 'Invalid OTP.') {
          setOtpVerified({
            valid: false,
            error: 'Incorrect code, please try again.',
          });
        } else {
          toast.error(
            message ||
              'An error occurred while resetting the password. Please try again later',
            toastStyle
          );
        }
      }
    });

  const resetOtpForm = () => {
    setOtpValue('');
    setVerifyOtp(false);
    setOtpVerified('');
    otpFormReset();
  };

  return (
    <div className="forgot-password-form-wrapper">
      <h1 className="header d-flex align-items-center">
        <Link
          to={ALL_ROUTES.AUTH_CHILDREN.LOGIN}
          style={{ textDecoration: 'none' }}
        >
          <ArrowBack />
        </Link>
        &nbsp;Forgot Password
      </h1>
      {verifyOtp ? (
        <Form className="forgot-password-form" onSubmit={handleOtpFormSubmit()}>
          <div className="forgot-password-form__item gx-3">
            <div className="forgot-password-form__item__note">
              We have sent you a <strong className="otp-digit">4 Digit</strong>{' '}
              verification code on your registered email to help you reset your
              Password.
              <br />
              Please verify the code below to reset your Password.
            </div>
          </div>
          <FormOTP
            label="Verification Code"
            onChange={handleOtpChange}
            handleResendOtp={resendOtp}
            otp={otpValue}
            success={otpVerified?.success}
            error={otpVerified?.error}
          />
          <FormPasswordInput
            label="New Password"
            placeholder="Please enter your new password"
            error={otpFormErrors?.new_password?.message}
            {...otpFormRegister('new_password', {
              required: 'New password is required',
              validate: value => {
                return validatePassword(value);
              },
            })}
          />
          <FormPasswordInput
            label="Confirm New Password"
            placeholder="Please re-enter your new password to confirm"
            error={otpFormErrors?.confirm_new_password?.message}
            {...otpFormRegister('confirm_new_password', {
              required: 'Confirm new password is required',
              validate: value => {
                return validatePasswordMatch(
                  otpFormGetValues('new_password'),
                  value
                );
              },
            })}
          />
          <div className="forgot-password-form__action">
            <Button
              label="Cancel"
              className="forgot-password-form__action__cancel"
              variant="red-outlined"
              onClick={resetOtpForm}
            />
            <Button
              label="Confirm"
              className="forgot-password-form__verify-button"
              type="submit"
            />
          </div>
        </Form>
      ) : (
        <Form
          className="forgot-password-form"
          onSubmit={handleEmailFormSubmit()}
        >
          <FormInput
            label="Email"
            type="email"
            placeholder="Please enter your registered valid email ID"
            error={emailFormErrors?.email?.message}
            {...emailFormRegister('email', {
              required: 'Email is required field',
            })}
          />
          <Button
            label="Confirm"
            className="forgot-password-form__confirm-button"
            type="submit"
          />
        </Form>
      )}
    </div>
  );
};

export default ForgotPassword;
