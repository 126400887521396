import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import FormInput from 'components/FormInput';
import FormCheckbox from 'components/FormCheckbox';
import Button from 'components/Button';
import FormPasswordInput from 'components/FormPasswordInput';
import FormOTP from 'components/FormOTP';
import SocialLogin from 'components/SocialLogin';
import ALL_ROUTES from 'config/routes';
import { useAuthContext } from 'layouts/AuthLayout/contexts/AuthContext';
import FormSelect from 'components/FormSelect';
import { profileOptions } from 'utils/constants';
import APP_CONFIG from 'config/app-config';
import { handleLoginSuccess, handleRememberMeSetting } from 'utils/common';
import { validatePassword, validatePasswordMatch } from 'utils/validator';

import './styles.scss';
import { toastStyle } from 'utils/helper';
//import { toastStyle } from 'utils/helper';

const Signup = () => {
  const {
    signupMutation,
    verifyEmailMutation,
    resendOtpMutation,
    verifyOtpMutation,
    handleSocialLogin,
  } = useAuthContext();
  const navigate = useNavigate();
  const [verifyOtp, setVerifyOtp] = useState(false);
  const [otpValue, setOtpValue] = useState(null);
  const [otpVerified, setOtpVerified] = useState(null);
  const {
    register: emailFormRegister,
    handleSubmit: emailFormSubmit,
    formState: { errors: emailFormErrors, isValid: emailFormValid },
    getValues: emailFormGetValues,
    control: emailFormControl,
  } = useForm({ mode: 'onChange' });
  const {
    register: otpFormRegister,
    handleSubmit: otpFormSubmit,
    formState: { errors: otpFormErrors, isValid: otpFormValid },
    getValues: otpFormGetValues,
    trigger: otpFormTrigger,
    reset: otpFormReset,
    watch: otpFormWatch,
  } = useForm({ mode: 'onChange' });
  const password = otpFormWatch('password');

  useEffect(() => {
    if (otpFormGetValues('confirm_password')) {
      otpFormTrigger('confirm_password');
    }
  }, [password, otpFormGetValues, otpFormTrigger]);

  const verifyOtpValue = async otp => {
    try {
      await verifyOtpMutation.mutateAsync({
        email_otp: otp,
        email: emailFormGetValues('email'),
      });
      setOtpVerified({
        valid: true,
        success: 'Code verified.',
      });
    } catch (error) {
      const message =
        error?.response?.data?.message?.non_field_errors?.[0] ||
        error?.response?.data?.error;
      if (message === 'Invalid OTP.') {
        setOtpVerified({
          valid: false,
          error: 'Incorrect code, please try again.',
        });
        // resendOtp();
        // setOtpValue(null);
      } else {
        toast.error(
          message ||
            'An error occurred while verifying the OTP. Please try again later',
          toastStyle
        );
        // setOtpValue(null);
        // resendOtp();
      }
    }
  };

  const handleOtpChange = otp => {
    setOtpValue(otp);
    setOtpVerified(null);

    if (otp?.length === 4) {
      verifyOtpValue(otp);
    }
  };

  const handleEmailFormSubmit = () =>
    emailFormSubmit(async data => {
      try {
        await verifyEmailMutation.mutateAsync({
          ...data,
        });
        setVerifyOtp(true);
        toast.success('OTP has been sent to your email address', toastStyle);
      } catch (error) {
        if (
          (error?.response?.data?.email?.[0] ||
            error?.response?.data?.message?.email?.[0]) ===
          'A user is already registered with this e-mail address.'
        ) {
          resendOtp();
        } else {
          const errorMessage =
            error?.response?.data?.non_field_errors?.[0] ||
            error?.response?.data?.message?.non_field_errors?.[0] ||
            error?.response?.data?.error;
          toast.error(
            errorMessage ||
              'An error occurred while sign up. Please try again later',
            toastStyle
          );
        }
      }
    });

  const resendOtp = async () => {
    setOtpValue(null);
    setOtpVerified(null);
    const data = { email: emailFormGetValues('email') };
    try {
      await resendOtpMutation.mutateAsync({
        ...data,
      });
      setVerifyOtp(true);
      toast.success('OTP has been sent to email address.', toastStyle);
    } catch (error) {
      const errorMessage =
        error?.response?.data?.non_field_errors?.[0] ||
        error?.response?.data?.message?.non_field_errors?.[0] ||
        error?.response?.data?.error;
      toast.error(
        errorMessage ||
          'An error occurred while resending the OTP. Please try again later',
        toastStyle
      );
    }
  };

  const handleOtpFormSubmit = () =>
    otpFormSubmit(async data => {
      toast.loading('Please wait while registering your account...');
      try {
        const response = await signupMutation.mutateAsync({
          email: emailFormGetValues('email'),
          user_type: emailFormGetValues('user_type')?.value,
          name: emailFormGetValues('name'),
          is_email_verified: Boolean(otpVerified?.success),
          ...data,
        });
        if (response?.data?.token) {
          handleRememberMeSetting(
            data?.remember_me,
            emailFormGetValues('email')
          );
          resetOtpForm();

          const userData = {
            name: response?.data?.name,
            id: response?.data?.id,
            email: response?.data?.email,
            social_signup: false,
          };
          handleLoginSuccess(response?.data?.token, userData, navigate);
        }
        toast.dismiss();
      } catch (error) {
        const message =
          error?.response?.data?.email?.[0] ||
          error?.response?.data?.name?.[0] ||
          error?.response?.data?.non_field_errors?.[0] ||
          error?.response?.data?.message?.non_field_errors?.[0] ||
          error?.response?.data?.error;

        toast.dismiss();
        toast.error(
          message || 'An error occurred while sign up. Please try again later',
          toastStyle
        );
      }
    });

  const onLoginSuccess = (provider, data) => {
    toast.loading('Please wait while registering your account...');
    handleSocialLogin(
      provider,
      data,
      response => {
        if (response?.data?.token) {
          const userData = {
            ...(response?.data?.user || {}),
            social_signup: response?.data?.social_signup,
          };
          handleLoginSuccess(response?.data?.token, userData, navigate);
        }
        toast.dismiss();
      },
      error => {
        const message =
          error?.response?.data?.email?.[0] ||
          error?.response?.data?.non_field_errors?.[0] ||
          error?.response?.data?.message?.non_field_errors?.[0] ||
          error?.response?.data?.error;

        toast.dismiss();
        toast.error(
          message || 'An error occurred while sign up. Please try again later',
          toastStyle
        );
      }
    );
  };

  const onLoginError = (provider, error) => {
    toast.error(
      'An error occurred while sign up. Please try again later',
      toastStyle
    );
  };

  const resetOtpForm = () => {
    setOtpValue('');
    setVerifyOtp(false);
    setOtpVerified(null);
    otpFormReset();
  };

  return (
    <div className="sign-up-form-wrapper">
      <h1 className="header">Sign Up</h1>
      {verifyOtp ? (
        <Form className="sign-up-form" onSubmit={handleOtpFormSubmit()}>
          <div className="sign-up-form__item gx-3">
            <div className="sign-up-form__item__label">
              {emailFormGetValues('name')}
            </div>
            <div className="sign-up-form__item__note">
              We have sent you a <strong className="otp-digit">4 Digit</strong>{' '}
              verification code on:
              <br />
              <span className="sign-up-form__item__note__email">
                {emailFormGetValues('email')}
              </span>
              <br />
              Please verify the code below to set your Password.
            </div>
          </div>
          <FormOTP
            label="Verification Code"
            onChange={handleOtpChange}
            handleResendOtp={resendOtp}
            otp={otpValue}
            success={otpVerified?.success}
            error={otpVerified?.error}
          />
          <FormPasswordInput
            label="Password"
            placeholder="Please enter a password"
            error={otpFormErrors?.password?.message}
            {...otpFormRegister('password', {
              required: 'Password is required',
              validate: value => {
                return validatePassword(value);
              },
            })}
          />
          <FormPasswordInput
            label="Confirm Password"
            placeholder="Please re-enter your password to confirm"
            error={otpFormErrors?.confirm_password?.message}
            {...otpFormRegister('confirm_password', {
              required: 'Confirm password is required',
              validate: value => {
                return validatePasswordMatch(
                  otpFormGetValues('password'),
                  value
                );
              },
            })}
          />
          <FormCheckbox
            label="Remember Me/Keep Me Signed In"
            labelClassName="remember-me-label"
            {...otpFormRegister('remember_me', { required: false })}
          />
          <div className="sign-up-form__action">
            <Button
              label="Cancel"
              className="sign-up-form__action__cancel"
              variant="red-outlined"
              onClick={resetOtpForm}
            />
            <Button
              label="Sign Up"
              className="sign-up-form__signup-button"
              type="submit"
              disabled={!otpVerified?.valid || !otpFormValid}
            />
          </div>
        </Form>
      ) : (
        <Form onSubmit={handleEmailFormSubmit()} className="sign-up-form">
          <FormInput
            label="Name"
            type="text"
            placeholder="Please enter your full name"
            error={emailFormErrors?.name?.message}
            {...emailFormRegister('name', { required: 'Name is required' })}
          />
          {/* <FormSelect
            label="Account Type"
            placeholder="Please select an account type"
            options={profileOptions}
            error={emailFormErrors?.user_type?.message}
            name="user_type"
            rules={{
              required: 'Account type is required',
            }}
            control={emailFormControl}
          /> */}
          <FormInput
            label="Email"
            type="email"
            placeholder="Please enter a valid email"
            error={emailFormErrors?.email?.message}
            {...emailFormRegister('email', { required: 'Email is required' })}
          />
          <FormCheckbox
            label={
              <span className="sign-up-form__accept-tos">
                I have read the{' '}
                <a
                  className="sign-up-form__accept-tos__link"
                  href="https://www.cinebudgetpro.com/terms-of-service"
                >
                  Terms & Conditions
                </a>{' '}
                and{' '}
                <a
                  className="sign-up-form__accept-tos__link"
                  href="https://www.cinebudgetpro.com/privacy-policy"
                >
                  Privacy Policy
                </a>
              </span>
            }
            error={emailFormErrors?.TOS?.message}
            {...emailFormRegister('TOS', {
              required: 'Please accept terms and conditions',
            })}
          />
          <Button
            label="Verify Email"
            type="submit"
            className="sign-up-form__sign-up-button"
            disabled={!emailFormValid}
          />
          <SocialLogin
            type="Sign Up"
            onLoginSuccess={onLoginSuccess}
            onLoginError={onLoginError}
          />
          <div className="sign-up-form__have-account">
            Already have an account?{' '}
            <Link className="sign-in-link" to={ALL_ROUTES.AUTH_CHILDREN.LOGIN}>
              Sign In
            </Link>
          </div>
        </Form>
      )}
    </div>
  );
};

export default Signup;
