import React from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton } from 'react-bootstrap';

import './styles.scss';

const Button = React.forwardRef(
  ({ className, label, type, variant, onClick, isDisabled, ...rest }, ref) => {
    return (
      <div className="button-wrapper">
        <BootstrapButton
          variant={variant}
          type={type}
          className={`cb-app-button button-${variant} ${className}`}
          onClick={onClick}
          {...rest}
          ref={ref}
          disabled={isDisabled}
        >
          {label}
        </BootstrapButton>
      </div>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  type: 'button',
  variant: 'primary',
  onClick: () => null,
  isDisabled: false,
};

export default Button;
