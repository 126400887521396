export const validatePassword = password => {
  const caps = (password?.match(/[A-Z]/g) || [])?.length;
  const small = (password?.match(/[a-z]/g) || [])?.length;
  const num = (password?.match(/[0-9]/g) || [])?.length;
  const specialSymbol = (password?.match(/\W/g) || [])?.length;

  if (
    password?.length < 8 ||
    caps < 1 ||
    small < 1 ||
    num < 1 ||
    specialSymbol < 1
  ) {
    return 'Password must have at least 8 characters, one capital letter, one number and one special character.';
  }

  return true;
};

export const validatePasswordMatch = (password, confirmPassword) => {
  if (password !== confirmPassword) {
    return 'The password and confirm password do not match.';
  }
  return true;
};

export const validateEmail = email => {
  if (
    /^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
      email
    )
  ) {
    return true;
  }

  return false;
};
