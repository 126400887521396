import { post, get, patch, del } from './api-handler.js';

const API_ENDPOINTS = {
  // ONBOARDING
  onboarding: {
    user_login: payload => post('/api/v1/login/', payload),
    verify_email: payload => post('/api/v1/send-email-otp/', payload),
    user_signup: payload => post('/api/v1/signup/', payload),
    verify_otp: payload => post('/api/v1/verify-email-otp/', payload),
    resend_otp: payload => post('/api/v1/resend-email-otp/', payload),
    forgot_password: payload => post('/api/v1/forgot-password/', payload),
    forgot_password_verify_otp: payload =>
      post('/api/v1/forgot-password-verify-otp/', payload),
    forgot_password_resend_otp: payload =>
      post('/api/v1/resend-forgot-password/', payload),
    social_login: (provider, payload) =>
      post(`/modules/social-auth/${provider}/login/`, payload),
  },
  account_settings: {
    change_password: payload => post('/api/v1/change-password/', payload),
    user_logout: payload => post('/api/v1/user-logout/', payload),
    get_user_profile: async () => await get('/users/api/v1/user-profile/'),
    create_user_profile: payload =>
      post('/users/api/v1/user-profile/', payload),
    update_user_profile: ({ setUploadStatus, headers, id, ...payload }) => {
      return patch(`/users/api/v1/user-profile/${id}/`, payload, {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadStatus &&
            setUploadStatus({
              uploading: true,
              percentCompleted,
            });
        },
        ...(headers || {}),
      });
    },
    search_user: searchTerm =>
      get(`/users/api/v1/search-user/?name=${searchTerm}`),
  },
  budgets: {
    get_folders: () => get('/budget/api/v1/folder-budget/?folders_only=true'),
    get_folder_by_id: folderId =>
      get(
        `/budget/api/v1/folder-budget/?folders_only=true&parent_folder=${folderId}`
      ),
    move_budget: payload =>
      patch(`/budget/api/v1/create-budget/${payload?.budgetId}/`, payload),
    move_budget_folder: payload =>
      patch(`/budget/api/v1/create-budget/move_budget_folder/`, payload),
    delete_folder: folderId => del(`/budget/api/v1/folders/${folderId}/`),
    create_budget: payload => post('/budget/api/v1/create-budget/', payload),
    update_budget: payload =>
      patch(`/budget/api/v1/create-budget/${payload?.id}/`, payload),
    create_folder: payload => post('/budget/api/v1/folders/', payload),
    update_folder: payload =>
      patch(`/budget/api/v1/folders/${payload?.id}/`, payload),
    get_budgets: () => get('/budget/api/v1/create-budget/'),
    get_budget_by_id: budgetId =>
      get(`/budget/api/v1/create-budget/${budgetId}/`),
    duplicate_budget: payload =>
      post(`budget/api/v1/duplicate-budget/`, payload),
    duplicate_budget_values: payload =>
      post(`budget/api/v1/duplicate-budget-values/`, payload),
    delete_budget: budgetId => del(`/budget/api/v1/create-budget/${budgetId}/`),
    get_budget_types: () => get('/budget/api/v1/budget-type/'),
    //budget swimlanes
    create_swimlane: payload => post('/budget/api/v1/swimlane/', payload),
    delete_swimlane: id => del(`/budget/api/v1/swimlane/${id}/`),
    create_line_item: payload => post('/budget/api/v1/line-item/', payload),
    delete_line_item: id => del(`/budget/api/v1/line-item/${id}/`),
    create_line_item_children: payload =>
      post('/budget/api/v1/line-item-children/', payload),
    delete_line_item_children: id =>
      del(`/budget/api/v1/line-item-children/${id}/`),
    add_sub_category_swimlane: id =>
      post(`/budget/api/v1/swimlane/${id}/add-sub-category/`),
    add_sub_category_line_item: id =>
      post(`/budget/api/v1/line-item/${id}/add-sub-category/`),
    update_swimlane: payload =>
      patch(`/budget/api/v1/swimlane/${payload.id}/`, payload),
    update_line_item: payload =>
      patch(`/budget/api/v1/line-item/${payload.id}/`, payload),
    update_line_item_children: payload =>
      patch(`/budget/api/v1/line-item-children/${payload.id}/`, payload),
    get_swimlanes: budgetId =>
      get(`/budget/api/v1/swimlane/?budget=${budgetId}`),
    get_line_items: swimlaneId =>
      get(`/budget/api/v1/line-item/?swimlane=${swimlaneId}`),
    get_line_item_children: parentId =>
      get(`/budget/api/v1/line-item-children/?parent=${parentId}`),
    // swimlane details sheet
    get_swimlane_detailed_sheet: async swimlaneId =>
      get(`/budget/api/v1/swimlane-detail-sheet/${swimlaneId}/`),
    get_line_item_detailed_sheet: itemId =>
      get(`/budget/api/v1/lineitem-detail-sheet/${itemId}/`),
    get_line_item_children_detailed_sheet: itemId =>
      get(`/budget/api/v1/lineitem-children-detail-sheet/${itemId}/`),
    create_swimlane_detail_sheet: payload =>
      post(`/budget/api/v1/swimlane-detail-sheet/`, payload),
    delete_swimlane_details_sheet: id =>
      del(`/budget/api/v1/swimlane-detail-sheet/${id}/`),
    update_swimlane_detail_sheet: payload =>
      patch(`/budget/api/v1/swimlane-detail-sheet/${payload.id}/`, payload),
    create_line_item_detailed_sheet: payload =>
      post(`/budget/api/v1/lineitem-detail-sheet/`, payload),
    delete_line_item_details_sheet: id =>
      del(`/budget/api/v1/lineitem-detail-sheet/${id}/`),
    update_line_item_detailed_sheet: payload =>
      patch(`/budget/api/v1/lineitem-detail-sheet/${payload.id}/`, payload),
    create_line_item_children_detailed_sheet: payload =>
      post(`/budget/api/v1/lineitem-children-detail-sheet/`, payload),
    delete_line_item_children_details_sheet: id =>
      del(`/budget/api/v1/lineitem-children-detail-sheet/${id}/`),
    update_line_item_children_detailed_sheet: payload =>
      patch(
        `/budget/api/v1/lineitem-children-detail-sheet/${payload.id}/`,
        payload
      ),
    get_detail_sheet_unit_type: () =>
      get('/budget/api/v1/detail-sheet-unit-type/'),
    // collaborator
    get_permissions: () => get('/budget/api/v1/permissions/'),
    get_collaborators: budgetId =>
      get(
        `/budget/api/v1/registered-invited-collaborators/?budget_id=${budgetId}`
      ),
    share_budget: payload =>
      post('/budget/api/v1/invite-collaborators/', payload),
    remove_budget_access: payload =>
      del(`/budget/api/v1/invite-collaborators/${payload?.id}/`),
    get_budget_permissions: budgetId =>
      get(`/budget/api/v1/budget-permissions/?budget=${budgetId}`),
    // get full budget data and download budget
    get_budget_data: budgetId => get(`/budget/api/v1/budget-data/${budgetId}/`),
    get_budget_details_data: payload => {
      if (payload?.parent_folder) {
        if (payload?.adv_search === true) {
          return get(
            `/budget/api/v1/folder-budget/?advanced_search=${payload?.adv_search}&search=${payload?.search}&parent_folder=${payload?.parent_folder}`
          );
        } else {
          return get(
            `/budget/api/v1/folder-budget/?search=${payload?.search}&parent_folder=${payload?.parent_folder}`
          );
        }
      } else {
        if (payload?.adv_search === true) {
          return get(
            `/budget/api/v1/folder-budget/?advanced_search=${payload?.adv_search}&search=${payload?.search}`
          );
        } else {
          return get(`/budget/api/v1/folder-budget/?search=${payload?.search}`);
        }
      }
    },

    delete_multi_budgets: payload =>
      del(`/budget/api/v1/create-budget/delete_multiple/`, payload),

    download_budget: payload =>
      post(`/budget/api/v1/convert-to-pdf/`, payload, { responseType: 'blob' }),
  },
  tabs: {
    get_open_tabs: () => get('/budget/api/v1/open-tabs/'),
    make_active_tab: tabId =>
      post(`/budget/api/v1/open-tabs/${tabId}/make-active/`, {}),
    create_tabs: payload => post('/budget/api/v1/open-tabs/', payload),
    close_tab: tabId => del(`/budget/api/v1/open-tabs/${tabId}/`),
  },
  contextual_suggestion: {
    get_contextual_suggestions: () =>
      get('/contextual-suggestion/api/v1/contextual-suggestions/'),
  },
  user_activity: {
    post_user_activity: payload =>
      post('/users/api/v1/user-activity/', payload),
  },
  reorder_swimlane: {
    reorder_swimlane: payload =>
      post('/budget/api/v1/reposition-swimlane/', payload),
  },
  reorder_line_item: {
    reorder_line_item: payload =>
      post('/budget/api/v1/reposition-line-items/', payload),
  },
};
export default API_ENDPOINTS;
