const ALL_ROUTES = {
  HOME: '/',
  AUTH: '/auth',
  AUTH_CHILDREN: {
    LOGIN: '/auth/login',
    SIGNUP: '/auth/signup',
    FORGOT_PASSWORD: '/auth/forgot-password',
  },
  HOME_SCREEN: '/home',
  HOME_SCREEN_CHILDREN: {
    ACCOUNT_SETTINGS: '/home/settings/account',
    PRINT_BUDGET: '/home/print-budget',
  },
  BUDGET_DETAILS: '/home/budget-listing',
  ACCOUNT_SETTINGS_CHILDREN: {
    PROFILE: '/home/settings/account/profile',
    PAYMENTS: '/home/settings/account/payments',
    SUBSCRIPTION: '/home/settings/account/subscription',
    NOTIFICATIONS: '/home/settings/account/notifications',
    CHANGE_PASSWORD: '/home/settings/account/change-password',
    CONTACT_US: '/home/settings/account/contact-us',
    INVITE_FRIENDS: '/home/settings/account/invite-friends',
    SUPPORT_FEEDBACK: '/home/settings/account/support-feedback',
    PRIVACY_POLICY: '/home/settings/account/privacy-policy',
    TERMS_CONDITIONS: '/home/settings/account/terms-conditions',
    FAQS: '/home/settings/account/faqs',
  },
};

export default ALL_ROUTES;
