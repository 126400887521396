import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { Table } from 'react-bootstrap';
import { useAppContext } from 'contexts/AppContext';
import { useBudgetQueries } from 'utils/api/queries';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import ItemRow from './ItemRow';
import {
  calculateDetailsTotal,
  deleteSwimlaneDetailsItem,
  getColumnConf,
  getDetailsSheet,
  handlePartialDataUpdate,
} from 'utils/common';
import { debounce, formatCurrency, sortArray } from 'utils/helper';
import './styles.scss';

const DetailedView = ({ setNavWidth }) => {
  const { isUserLoggedIn, unitTypes } = useAppContext();
  const queryClient = useQueryClient();
  const {
    showDetailedView,
    selectedBudget,
    createSwimlaneDetailsMutation,
    updateSwimlaneDetailsMutation,
    updateSwimlaneCosts,
    refetchBudgetId,
    hasEditAccess,
    deleteSwimlaneDetailsMutation,
  } = useHomeScreenContext();
  // const [detailsRows, setDetailsRow] = useState([]);
  const mutationCache = useRef({});
  const [hiddenColumn, setHiddenColumn] = useState({ comment: true });
  const { level, selectedItem, swimlane } = showDetailedView;
  const [detailsRows, setDetailsRow] = useState({ [selectedItem?.id]: [] });
  const [detailsTotal, setDetailsTotal] = useState(0);
  const [selectedSwimlane, setSelectedSwimlane] = useState({});
  const type =
    level === 0 ? 'swimlane' : level === 1 ? 'line-item' : 'line-item-children';
  const id =
    level === 0
      ? selectedBudget?.budget
      : level === 1
      ? selectedItem?.swimlane
      : selectedItem?.parent;
  const { data, refetch } = useBudgetQueries.useSwimlaneQuery({
    enabled: Boolean(isUserLoggedIn && type && id),
    payload: {
      id,
      type,
    },
  });

  const details = getDetailsSheet(
    data?.data?.find(item => item?.id === selectedItem?.id),
    type
  );

  useEffect(() => {
    let swimlaneChanged = false;

    if (selectedItem?.id !== selectedSwimlane?.id) {
      setSelectedSwimlane(selectedItem);
      swimlaneChanged = true;

      const updatedDetails = handlePartialDataUpdate(
        swimlaneChanged ? [] : detailsRows[selectedItem?.id],
        details,
        selectedItem,
        type
      );

      calculateDetailsTotal([...updatedDetails], setDetailsTotal);
      setDetailsRow(prev => ({
        ...prev,
        [selectedItem?.id]: [...updatedDetails],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, selectedItem, type]);

  const toggleColumn = column => () => {
    setHiddenColumn({ ...hiddenColumn, [column]: !hiddenColumn[column] });
    setNavWidth?.();
  };

  const expandColumn = column => () => {
    setHiddenColumn({ ...hiddenColumn, [column]: false });
    setNavWidth?.();
  };

  const callHiddenCommentParent = () => {
    setHiddenColumn({ ...hiddenColumn, comment: false });
    setNavWidth?.();
  };
  const columns = getColumnConf(selectedBudget, unitTypes, toggleColumn);

  const addEmptyRow = () => {
    const index =
      (Math.max(...detailsRows[selectedItem?.id].map(row => row.row_order)) ||
        0) + 1;
    setDetailsRow({
      ...detailsRows,
      [selectedItem?.id]: [
        ...detailsRows[selectedItem?.id],
        {
          row_id: `row-${type}-${selectedItem?.id}-${index}`,
          row_order: index,
        },
      ],
    });
  };

  const prefillDecimals = useCallback(
    rowData =>
      Object.keys(rowData).reduce((acc, fieldKey) => {
        const isDecimal = columns.some(
          col => col.key === fieldKey && col.dataType === 'decimal'
        );
        acc[fieldKey] ||= isDecimal ? 0 : acc[fieldKey];
        return acc;
      }, rowData),
    []
  );

  const clearMutateCache = (rowId, mutateCache) => {
    return Object.keys(mutateCache || {}).reduce((acc, rowIndex) => {
      if (id !== rowId) {
        return { ...acc, [rowIndex]: mutateCache?.[id] };
      }
      return acc;
    }, {});
  };

  const handleAddRow = useMemo(
    () =>
      debounce((rowData, totalCost) => {
        const mutateMethod = rowData?.id
          ? updateSwimlaneDetailsMutation
          : createSwimlaneDetailsMutation;
        const payload = {
          details: {
            ...(!rowData?.id ? { item: '' } : null),
            ...prefillDecimals(rowData),
          },
          selectedItem,
          swimlane,
          type,
        };

        if (!mutationCache.current[rowData.row_id]?.inProgress) {
          mutationCache.current = {
            ...mutationCache.current,
            [rowData.row_id]: {
              inProgress: 1,
              selectedItemId: selectedItem?.id,
            },
          };
          mutateMethod.mutate(payload, {
            onSuccess: response => {
              const newData = response.data || rowData;
              const updatesInProgress = mutationCache.current;

              // if there is another request in queue, trigger that
              if (updatesInProgress[rowData.row_id]?.update) {
                updatesInProgress[rowData.row_id]?.update?.(newData?.id);
              } else {
                mutationCache.current = clearMutateCache(
                  rowData.row_id,
                  updatesInProgress
                );
                refetch?.();
                refetchBudgetId?.();

                setDetailsRow(prev => ({
                  ...prev,
                  [selectedItem?.id]: [
                    ...handlePartialDataUpdate(
                      prev[selectedItem?.id],
                      [{ ...rowData, ...newData }],
                      selectedItem,
                      type
                    ),
                  ],
                }));
              }
            },
            onError: () => {
              const updatesInProgress = mutationCache.current;
              if (updatesInProgress[rowData.row_id]?.update) {
                updatesInProgress[rowData.row_id]?.update?.();
              } else {
                mutationCache.current = clearMutateCache(
                  rowData.row_id,
                  updatesInProgress
                );
              }
            },
          });
        }
        updateSwimlaneCosts(totalCost, selectedItem);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prefillDecimals, selectedItem, swimlane, type]
  );

  const addRow = useCallback(
    rowData => {
      let newDetails = [...detailsRows[selectedItem?.id]];
      const rowItems = [];

      if (
        rowData.row_id &&
        detailsRows[selectedItem?.id]?.some(
          item => item?.row_id === rowData?.row_id
        )
      ) {
        newDetails = newDetails?.map(item => {
          if (rowData?.row_id && item?.row_id === rowData?.row_id) {
            rowItems.push(rowData);
            return rowData;
          }
          return item;
        });
      } else {
        rowItems.push(rowData);
        newDetails = [...newDetails, rowData];
      }

      if (rowItems?.length) {
        // setDetailsRow(prev =>
        //   handlePartialDataUpdate(prev, [{ ...rowData }], selectedItem, type)
        // );
        setDetailsRow(prev => ({
          ...prev,
          [selectedItem?.id]: [
            ...handlePartialDataUpdate(
              prev[selectedItem?.id],
              [{ ...rowData }],
              selectedItem,
              type
            ),
          ],
        }));
        const totalCost = calculateDetailsTotal(newDetails, setDetailsTotal);
        const updatesInProgress = mutationCache.current;

        if (updatesInProgress?.[rowData.row_id]?.inProgress) {
          // delay the update if already one in progress
          mutationCache.current = {
            ...mutationCache.current,
            [rowData.row_id]: {
              inProgress: 1,
              selectedItemId: selectedItem?.id,
              update: newId => {
                const currentState = mutationCache.current;
                delete mutationCache.current?.[rowData.row_id];
                mutationCache.current = {
                  ...currentState,
                  [rowData.row_id]: {
                    inProgress: false,
                    newId,
                  },
                };
                handleAddRow(
                  { ...rowData, ...(newId ? { id: newId } : null) },
                  totalCost
                );
              },
            },
          };
        } else {
          const newId = updatesInProgress?.[rowData.row_id]?.newId;
          handleAddRow(
            { ...rowData, ...(newId ? { id: newId } : null) },
            totalCost
          );
        }
      }
    },
    [handleAddRow, detailsRows]
  );

  const handleRowAction = useCallback(
    item => action => {
      if (action === 'delete-item') {
        const payload = { type, id: item?.id };

        if (item?.id) {
          deleteSwimlaneDetailsMutation.mutate(payload, {
            onSuccess: () => {
              refetchBudgetId?.();
              deleteSwimlaneDetailsItem(queryClient, item, selectedItem, {
                type,
                id,
              });
            },
          });
        }
        const newDetails =
          detailsRows[selectedItem?.id]?.filter(
            row => row?.row_id !== item?.row_id
          ) || [];
        setDetailsRow(prev => ({
          ...prev,
          [selectedItem?.id]: [...newDetails],
        }));
        const totalCost = calculateDetailsTotal(newDetails, setDetailsTotal);
        updateSwimlaneCosts(totalCost, selectedItem);
      }
    },
    [
      detailsRows,
      deleteSwimlaneDetailsMutation,
      updateSwimlaneCosts,
      refetchBudgetId,
      selectedItem,
      type,
      id,
      queryClient,
    ]
  );

  return (
    <Table striped bordered hover className="detailed-view-table">
      <thead>
        <tr>
          {columns?.map(column => (
            <th
              key={column.key}
              style={
                hiddenColumn[column.key]
                  ? { width: '40px' }
                  : { width: column.width }
              }
              className={column.key}
              onClick={column.onClick}
            >
              <div>
                {!hiddenColumn[column.key] && column.label}
                {column.icon}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortArray(detailsRows[selectedItem?.id], 'row_order')?.map(
          (rowData, index) => {
            return (
              <ItemRow
                callHiddenCommentParent={callHiddenCommentParent}
                // key={rowData?.row_id}
                key={rowData?.id || rowData?.row_id}
                columns={columns}
                rowData={rowData}
                addRow={addRow}
                hiddenColumn={hiddenColumn}
                expandColumn={expandColumn}
                canEdit={hasEditAccess()}
                addEmptyRow={addEmptyRow}
                handleRowAction={handleRowAction}
                hideOptions={Boolean(
                  index + 1 === detailsRows[selectedItem?.id].length &&
                    !rowData?.id
                )}
              />
            );
          }
        )}
      </tbody>
      <tfoot className="detailed-view-table-foot">
        <tr>
          <td colSpan="7">
            {selectedItem?.name} Sub-Total:{' '}
            {formatCurrency(
              detailsTotal || 0,
              selectedBudget?.primary_currency
            )}
          </td>
          <td
            className="sub-total-row-fill"
            style={
              hiddenColumn['comment']
                ? { width: '40px' }
                : { minWidth: '250px' }
            }
          >
            &nbsp;
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

DetailedView.propTypes = {
  setNavWidth: PropTypes.func,
};

DetailedView.defaultProps = {
  setNavWidth: null,
};

export default DetailedView;
