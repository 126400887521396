import React, { useEffect, useState } from 'react';
import { useMatch, Outlet } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { AuthContextProvider } from './contexts/AuthContext';
import ALL_ROUTES from 'config/routes';
import { ReactComponent as GroupsIcon } from 'assets/images/groups-icon.svg';
import { ReactComponent as SaveIcon } from 'assets/images/save-icon.svg';
import { ReactComponent as CategoryIcon } from 'assets/images/category-icon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg';
import { ReactComponent as CinemaTapeImage } from 'assets/images/cinema-tape.svg';

import './styles.scss';
const AuthLayout = () => {
  const isSignUp = useMatch(ALL_ROUTES.AUTH_CHILDREN.SIGNUP);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {windowWidth < 450 ? (
        <div>
          <div className="mobile-view-logo">
            <Logo width={'50%'} height={'50%'} />
          </div>
          <div className="mobile-view">
            {' '}
            {`Mobile App coming soon, please sign in on desktop.`}
          </div>
        </div>
      ) : (
        <AuthContextProvider>
          <div className="auth-layout container-fluid p-0">
            <div className="auth-layout__background" />
            <div className="auth-layout__cinema-tape">
              <CinemaTapeImage />
            </div>
            <div className="d-flex auth-layout__container row mx-5">
              <div className="auth-layout__container__left-section col-xs-12 col-md-6 pt-5 ps-0">
                <div className="app-logo">
                  <Logo />
                </div>
                <div
                  className={`app-welcome-content ${
                    isSignUp
                      ? 'app-welcome-content--signup'
                      : 'app-welcome-content--signin'
                  }`}
                >
                  {!isSignUp && (
                    <div className="app-welcome-text">
                      <div className="app-welcome-text__title">
                        Welcome back to
                      </div>
                      <div className="app-welcome-text__name">
                        CineBudget Pro
                      </div>
                    </div>
                  )}
                  <div className="app-intro">
                    <div className="app-intro__text">
                      Easily build budgets for your creative projects
                    </div>
                    <div
                      className={`app-intro__video  ${
                        isSignUp
                          ? 'app-intro__video--signup'
                          : 'app-intro__video--signin'
                      }`}
                    >
                      <iframe
                        width="100%"
                        height="100%"
                        src="https://www.youtube.com/embed/0hw3CzfS0YM?si=gXjGiB70eY_0ml-w"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                  {!isSignUp && (
                    <div className="app-welcome">
                      <div className="app-welcome__description">
                        What&apos;s New
                        <ul>
                          <li>
                            <GroupsIcon />
                            Real Time Collaboration
                          </li>
                          <li>
                            <SaveIcon />
                            Auto Save
                          </li>
                          <li>
                            <CategoryIcon />
                            Customizable Category Depth
                          </li>
                          <li>
                            <CloseIcon />
                            Unit Multipliers
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="auth-layout__container__right-section col-xs-12 col-md-6">
                <div className="form-container col-md-9">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </AuthContextProvider>
      )}
    </>
  );
};

export default AuthLayout;
