import React, { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';

import './styles.scss';
import { prepareDropDownOptions } from 'utils/common';
import { toastStyle } from 'utils/helper';

const AddFolder = props => {
  const {
    createFolderMutation,
    refetchBudgets,
    budgetTypes,
    refetchFolders,
    showCreateFolder,
    hideCreateFolderModal,
    renameBudget,
    selectTab,
    refetchTabs,
    clearBudgetRename,
    refetchBudgetId,
  } = useHomeScreenContext();
  const [budgetTypeOptions, setBudgetTypeOptions] = useState(
    prepareDropDownOptions(
      [{ name: 'Blank Budget (No Template)', id: '' }, ...budgetTypes],
      'name',
      'id'
    )
  );
  const nameInputRef = useRef();

  // Use useEffect to focus on the text field when the component mounts
  useEffect(() => {
    // Check if the ref and the input element exist before focusing
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      name: renameBudget?.name || '',
    },
  });

  const { onChange, onBlur, name, ref } = register('name', {
    required: 'Folder name is required',
  });
  useEffect(() => {
    setBudgetTypeOptions(
      prepareDropDownOptions(
        [{ name: 'Blank Budget (No Template)', id: '' }, ...budgetTypes],
        'name',
        'id'
      )
    );
  }, [budgetTypes]);

  const handleCreateBudget = () =>
    handleSubmit(async data => {
      try {
        const response = await createFolderMutation.mutateAsync({
          ...data,
          id: renameBudget?.id,
          parent_folder: props?.parentid,
        });
        const newBudget = response?.data;
        newBudget?.id && selectTab(newBudget.id, newBudget);
        refetchBudgets?.();
        refetchBudgetId?.();
        toast.success(
          `Folder "${data?.name}" ${
            renameBudget?.id ? 'updated' : 'created'
          } successfully`,
          toastStyle
        );
        reset();
        refetchTabs();
        refetchFolders();
        props?.refetch();
        handleCancel();
        // window?.location?.reload();
      } catch (error) {
        const message =
          error?.response?.data?.template_type?.[0] ||
          error?.response?.data?.name?.[0] ||
          error?.response?.data?.non_field_errors?.[0];
        toast.error(
          message ||
            `An error occurred while ${
              renameBudget?.id ? 'updating' : 'creating'
            } folder. Please try again later`,
          toastStyle
        );
      }
    });

  const handleCancel = () => {
    hideCreateFolderModal();
    clearBudgetRename();
    reset();
    props?.closeFolder();
  };

  return (
    <Modal
      onHide={handleCancel}
      show={props?.showAddFolder}
      size="sm"
      className="create-budget"
      bodyClass="create-budget__body"
      centered={true}
      id="add-folder-popup"
    >
      <h1 className="create-budget__body__header">
        {renameBudget?.id ? 'Edit' : 'Create'} New Folder{' '}
        {watch('name') && (
          <span className="budget-name">&ldquo;{watch('name')}&rdquo;</span>
        )}
      </h1>
      <Form
        className="create-budget__body__form"
        onSubmit={handleCreateBudget()}
      >
        <FormInput
          label="Folder Name"
          type="text"
          showAutofocus={true}
          error={errors?.name?.message}
          placeholder="Please enter a folder name"
          {...register('name', { required: 'Folder name is required' })}
          // ref={nameInputRef}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          ref={ref}
          autoFocus={true}
        />
        <div className="create-budget__body__action">
          <Button
            label="Cancel"
            type="reset"
            className="create-budget__action__cancel"
            variant="red-outlined"
            onClick={handleCancel}
          />
          <Button
            label="Confirm"
            className="create-budget__action__confirm"
            type="submit"
          />
        </div>
      </Form>
    </Modal>
  );
};

AddFolder.propTypes = {
  parentid: PropTypes.string, // Assuming parentid is a string, adjust the type accordingly
  showAddFolder: PropTypes.bool,
  closeFolder: PropTypes.func,
  refetch: PropTypes.func.isRequired,
};

AddFolder.defaultProps = {
  parentid: '', // Default value for parentid (adjust based on actual default)
  showAddFolder: false, // Default value for showAddFolder (adjust based on actual default)
  closeFolder: undefined,
};

export default AddFolder;
