import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

const Chip = ({ className, title, icon, onClick, disabled }) => {
  return (
    <div
      className={`app-chip ${
        disabled ? 'app-chip--disabled' : ''
      } ${className}`}
      onClick={disabled ? undefined : onClick}
    >
      {title}
      {icon}
    </div>
  );
};

Chip.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

Chip.defaultProps = {
  className: '',
  icon: <></>,
  onClick: () => null,
  disabled: null,
};

export default Chip;
