import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Country, State } from 'country-state-city';

import Card from 'components/Card';
import FormInput from 'components/FormInput';
import FormSelect from 'components/FormSelect';

import './styles.scss';

const Payments = () => {
  const [editing, setEditing] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);

  const {
    register: addressFormRegister,
    handleSubmit: addressFormSubmit,
    formState: { errors: addressFormErrors },
    getValues: addressFormGetValues,
    control: addressFormControl,
    watch: addressFormWatch,
  } = useForm();

  const selectedCountry = addressFormWatch('country');
  useEffect(() => {
    const countries = Country.getAllCountries();

    const updatedCountries = countries.map(country => ({
      label: country.name,
      value: country.isoCode,
      ...country,
    }));
    setCountryOptions(updatedCountries);
  }, []);

  useEffect(() => {
    const updatedStates = State.getStatesOfCountry(selectedCountry?.value).map(
      state => ({ label: state.name, value: state.isoCode, ...state })
    );

    setStateOptions(updatedStates);
  }, [selectedCountry]);

  const handleEditClick = type => () => {
    setEditing({ ...editing, [type]: true });
  };

  const handleOnSave = type => () => {
    setEditing({ ...editing, [type]: false });
  };

  return (
    <div className="user-payments content-section">
      <h2 className="user-payments__header content-header">Payments</h2>
      <div className="user-payments__content content-body row">
        <div className="user-payments__content__col col-4">
          <Card
            className="address-info"
            title="Address Details"
            onEditClick={handleEditClick('address_info')}
            editMode={editing?.address_info}
            onSave={handleOnSave('address_info')}
            editable
          >
            <FormInput
              label="Address Line 1"
              type="text"
              placeholder="Please enter address line 1"
              error={addressFormErrors?.address1}
              readOnly={!editing?.address_info}
              {...addressFormRegister('address1', { required: true })}
            />
            <FormInput
              label="Address Line 2"
              type="text"
              placeholder="Please enter address line 2"
              error={addressFormErrors?.address2}
              readOnly={!editing?.address_info}
              {...addressFormRegister('address2', { required: false })}
            />
            {editing?.address_info ? (
              <>
                <FormSelect
                  label="Country"
                  placeholder="Please select a country"
                  options={countryOptions}
                  error={addressFormErrors?.country}
                  name="country"
                  rules={{
                    required: 'Country is required',
                  }}
                  control={addressFormControl}
                />
                <FormSelect
                  label="State"
                  placeholder="Please select a state"
                  options={stateOptions}
                  error={addressFormErrors?.state}
                  name="state"
                  rules={{
                    required: 'state is required',
                  }}
                  control={addressFormControl}
                />
                <FormInput
                  label="City"
                  type="text"
                  placeholder="Please enter city"
                  error={addressFormErrors?.city}
                  {...addressFormRegister('city', { required: true })}
                />
              </>
            ) : (
              <>
                <FormInput
                  label="City"
                  type="text"
                  placeholder="Please enter city"
                  readOnly={true}
                />
                <FormInput
                  label="State"
                  type="text"
                  placeholder="Please enter state"
                  readOnly={true}
                />
                <FormInput
                  label="Country"
                  type="text"
                  placeholder="Please enter country"
                  readOnly={!editing?.address_info}
                />
              </>
            )}
            <FormInput
              label="Zip Code"
              type="text"
              placeholder="Please enter zip code"
              error={addressFormErrors?.zip_code}
              readOnly={!editing?.address_info}
              {...addressFormRegister('zip_code', { required: true })}
            />
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Payments;
