import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { useController } from 'react-hook-form';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

import { ReactComponent as ErrorIcon } from 'assets/images/error-outline.svg';

import './styles.scss';

const FormPhoneInput = React.forwardRef(
  (
    {
      error,
      label,
      placeholder,
      inputClassName,
      labelClassName,
      helpText,
      control,
      name,
      rules,
      defaultCountry,
      defaultValue,
      disabled,
      id,
      ...rest
    },
    ref
  ) => {
    const {
      field: { value, ...restFields },
    } = useController({
      name,
      control,
      rules,
    });

    return (
      <Form.Group className="form-phone-group">
        <Form.Label
          className={`d-flex align-item-start w-100 form-phone-group__label ${labelClassName}`}
        >
          {label}
        </Form.Label>
        <PhoneInput
          id={id}
          key={id}
          defaultCountry={defaultCountry}
          placeholder={placeholder}
          disableCountryGuess
          disabled={disabled}
          {...rest}
          {...restFields}
          value={restFields?.value || defaultValue}
          forceDialCode={true}
          ref={ref}
          inputClassName={`${inputClassName} ${
            error ? 'form-control is-invalid' : ''
          }`}
        />
        {helpText && <Form.Text>{helpText}</Form.Text>}
        {error && (
          <Form.Control.Feedback type="invalid">
            <span className="error-icon">
              <ErrorIcon />
            </span>
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    );
  }
);

FormPhoneInput.displayName = 'FormInput';

FormPhoneInput.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  flagClassName: PropTypes.string,
  placeholder: PropTypes.string,
  control: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object.isRequired,
  defaultCountry: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

FormPhoneInput.defaultProps = {
  error: '',
  labelClassName: '',
  inputClassName: '',
  placeholder: '',
  flagClassName: '',
  helpText: '',
  defaultCountry: 'us',
  defaultValue: '',
  disabled: false,
  id: new Date().valueOf(),
};

export default FormPhoneInput;
