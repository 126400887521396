import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { ResizableBox } from 'react-resizable';
import { useQueryClient } from '@tanstack/react-query';
import 'react-resizable/css/styles.css';
import { ReactComponent as AddIcon } from 'assets/images/add-icon.svg';
import { ReactComponent as MoreIcon } from 'assets/images/more-vert.svg';
import { ReactComponent as DetailsIcon } from 'assets/images/details-icon.svg';
import { useBudgetQueries } from 'utils/api/queries';
import { useAppContext } from 'contexts/AppContext';
import AddItem from 'components/AddItem';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import DropDownMenu from 'components/DropDownMenu';
import { getDetailsSheet } from 'utils/common';
import {
  debounce,
  formatCurrency,
  sortArray,
  stopPropagation,
  toastStyle,
} from 'utils/helper';
import FormInput from 'components/FormInput';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

const Swimlane = ({
  swimlaneHeight,
  selectedItem,
  type,
  editing,
  setEditing,
  swimlane,
  swimlaneIndex,
  handleAddSwimLanes,
  addItemSuggestions,
  addDetailedView,
  budgetId,
}) => {
  const { isUserLoggedIn } = useAppContext();
  const queryClient = useQueryClient();
  const {
    createSwimlaneMutation,
    addSubCategoryMutation,
    deleteSwimlaneMutation,
    setProjectTotal,
    swimlanes,
    setSwimlanes,
    setShowDetailedView,
    updateSwimlaneCosts,
    refetchBudgetId,
    hasEditAccess,
    selectedBudget,
    updateSwimlaneItem,
    addedContextualSuggestions,
    setAddedContextualSuggestions,
  } = useHomeScreenContext();
  const { useReorderSwimlane, useReorderLineItem } = useAppContext();
  const isTempId = itemId => /^NEW_ITEM_/.test(itemId);

  const lineItemOptions = [
    {
      value: 'delete-item',
      label: 'Delete Line Item',
    },
    {
      value: 'add-sub-category',
      label: 'Add Sub Category',
    },
  ];
  const deleteMutateCache = useRef([]);
  const newItemCache = useRef({});
  const itemKeyRef = useRef({ uniqueKeys: {}, counter: 0 });
  const [showAddItemInput, setShowAddItemInput] = useState(false);
  const [swimlaneItems, setSwimLaneItems] = useState([]);
  const [activeItem, setActiveItem] = useState();
  const [subCategoryItem, setSubCategoryItem] = useState(null);
  const selectedItemId = budgetId || selectedItem?.id;
  const { data, refetch } = useBudgetQueries.useSwimlaneQuery({
    enabled: Boolean(
      isUserLoggedIn && type && selectedItemId && !isTempId(selectedItemId)
    ),
    payload: {
      id: swimlaneIndex === 0 ? budgetId : selectedItem?.id,
      type,
    },
  });
  const items = data?.data;

  useEffect(() => {
    setShowDetailedView(false);
  }, [setShowDetailedView]);

  useEffect(() => {
    setSwimLaneItems(items);
    type === 'swimlane' &&
      setProjectTotal(
        items?.reduce((total, itemInfo) => {
          total += isNaN(itemInfo.cost) ? 0 : parseFloat(itemInfo.cost);
          return total;
        }, 0)
      );
  }, [items, type, setProjectTotal]);

  useEffect(() => {
    if (activeItem?.id && newItemCache.current?.[activeItem?.id]) {
      const newItem = newItemCache.current[activeItem?.id];
      delete newItemCache.current[activeItem?.id];
      handleItemClick(newItem)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newItemCache.current, activeItem]);

  useEffect(() => {
    if (swimlaneIndex === 0) {
      const addedSuggestions = { ...addedContextualSuggestions };
      Object.keys(addedContextualSuggestions)
        .filter(name => !swimlaneItems?.some(item => item?.name === name))
        .forEach(name => {
          delete addedSuggestions[name];
          setAddedContextualSuggestions(addedSuggestions);
          handleAddItem(name);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedContextualSuggestions, swimlaneIndex]);

  const handleAddItemClick = () => {
    setShowAddItemInput(true);
  };

  const handleAddItem = (name, addMore) => {
    !addMore && setShowAddItemInput(false);

    if (!name) return;

    const newItemTempID = `NEW_ITEM_${new Date().valueOf()}`;
    const newItem = { name, id: newItemTempID };
    setSwimLaneItems([...swimlaneItems, { name, id: newItemTempID }]);
    const swimlaneColumnItems = [...(items || []), newItem];
    queryClient.setQueryData(
      ['get-swimlanes-in-budget', { id: budgetId, type }],
      {
        ...data,
        data: items?.length ? [...swimlaneColumnItems] : [newItem],
      }
    );

    const payload = { type, budgetId, selectedItem, name, swimlane };
    createSwimlaneMutation.mutate(payload, {
      onSuccess: response => {
        newItemCache.current = {
          ...newItemCache.current,
          [newItemTempID]: response?.data,
        };
        itemKeyRef.current.uniqueKeys[response?.data?.id] =
          itemKeyRef.current.uniqueKeys[newItemTempID];
        refetch?.();
        refetchBudgetId?.();
      },
    });
  };

  const addCategoryOnAction = (name, mytype, mySelectedItem, mySwimlane) => {
    const payload = {
      type: mytype,
      budgetId,
      selectedItem: mySelectedItem,
      name,
      swimlane: mySwimlane,
    };
    createSwimlaneMutation.mutate(payload, {
      onSuccess: response => {
        refetch?.();
        refetchBudgetId?.();
        handleItemClick(mySelectedItem)();
        setEditing({ [response?.data?.id]: true });
      },
    });
  };

  const handleItemClick = item => () => {
    const detailSheet = getDetailsSheet(item, type);
    refetch?.();
    refetchBudgetId?.();
    setActiveItem({
      ...item,
      rowId: itemKeyRef.current?.uniqueKeys?.[item.id],
    });
    handleAddSwimLanes(
      swimlaneIndex,
      item,
      swimlaneIndex === 0 ? item.id : item.swimlane,
      detailSheet?.length > 0
    );
  };

  const deleteItem = useMemo(
    () =>
      debounce(() => {
        const itemsToDelete = [...(deleteMutateCache.current || [])];
        try {
          deleteMutateCache.current = [];
          itemsToDelete?.forEach(item => {
            const payload = { type, id: item?.id };
            deleteSwimlaneMutation.mutateAsync(payload, {
              onSuccess: () => {
                refetchBudgetId?.();
              },
            });
          });
        } catch (e) {
          !deleteMutateCache.current?.length && refetch?.();
        }
      }, 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swimlanes, swimlaneIndex, type, activeItem, setShowDetailedView]
  );

  const handleCreateSubCategory = item => {
    const payload = {
      id: item?.id,
      type,
    };
    addSubCategoryMutation.mutate(payload, {
      onSuccess: async _ => {
        await refetch?.();
        setSubCategoryItem(item);
      },
    });
  };

  useEffect(() => {
    if (subCategoryItem) {
      const item = subCategoryItem;
      setSubCategoryItem(null);
      const updatedItem = items?.find(
        swimlaneItem => swimlaneItem.id === item.id
      );
      addCategoryOnAction(
        updatedItem?.name + ' Sub Category 2',
        swimlaneIndex === 0 ? 'line-item' : 'line-item-children',
        updatedItem,
        updatedItem?.swimlane || updatedItem?.id
      );
    }
  }, [subCategoryItem, items]);

  const handleDeleteAction = item => {
    deleteMutateCache.current?.push(item);
    const itemPayload = {
      id: swimlaneIndex === 0 ? budgetId : selectedItem?.id,
      type,
    };
    const addedSuggestions = { ...addedContextualSuggestions };
    delete addedSuggestions[item?.name];
    setAddedContextualSuggestions(addedSuggestions);
    queryClient.setQueryData(['get-swimlanes-in-budget', { ...itemPayload }], {
      ...data,
      data: items?.filter(swimlaneItem => swimlaneItem?.id !== item?.id) || [],
    });
    const updatedSwimlanes = swimlanes.slice(0, swimlaneIndex + 1);
    if (activeItem?.id === item?.id) {
      setActiveItem(null);
      setSwimlanes(updatedSwimlanes);
      setShowDetailedView(false);
    }
    updateSwimlaneCosts(0, item, updatedSwimlanes);
    deleteItem();
  };

  const handleOptionsChange = item => action => {
    if (action === 'delete-item') {
      handleDeleteAction(item);
    } else if (action === 'add-sub-category') {
      handleCreateSubCategory(item);
    }
  };

  const toggleEditor = item => e => {
    stopPropagation(e);
    setEditing({ [item?.id]: !editing[item?.id] });
  };

  const handleItemNameChange = item => e => {
    const name = e?.target.value;
    // console.log('name@', name);
    if (name === '') {
      toast.error('Category name cannot be empty', toastStyle);
      return;
    } else {
      if (item?.name !== name) {
        const payload = { ...item, name };
        const itemPayload = {
          id: swimlaneIndex === 0 ? budgetId : selectedItem?.id,
          type,
        };
        const swimlaneData = queryClient.getQueryData([
          'get-swimlanes-in-budget',
          { ...itemPayload },
        ]);
        queryClient.setQueryData(
          ['get-swimlanes-in-budget', { ...itemPayload }],
          {
            ...swimlaneData,
            data:
              swimlaneItems.map(swimlaneItem =>
                swimlaneItem?.id === item?.id ? payload : swimlaneItem
              ) || [],
          }
        );
        toggleEditor(item)();
        updateSwimlaneItem({ ...payload, type });
      } else {
        toggleEditor(item)();
      }
    }
  };

  const droppableIdCounter = useRef(0);

  const generateDroppableId = () => {
    droppableIdCounter.current += 1;
    return droppableIdCounter.current.toString();
  };
  // const droppableId = useMemo(() => {
  //   droppableIdCounter.current += 1;
  //   return droppableIdCounter.current.toString();
  // }, []);

  // const handleDragEnd = result => {
  //   console.log('drag result@', result);
  //   if (!result.destination) return;
  //   const reorderedItems = Array.from(swimlaneItems);
  //   const [removed] = reorderedItems.splice(result.source.index, 1);
  //   reorderedItems.splice(result.destination.index, 0, removed);
  //   console.log('reorderedItems@', reorderedItems);
  //   reorderedItems.map((item, index) => {
  //     console.log('item@:', item, 'index@:', index);
  //   });
  //   setSwimLaneItems(reorderedItems);
  //   // Update the order of swimlane items in your state or context
  //   // based on the reorderedItems array.
  // };

  const handleDragEnd = result => {
    console.log('drag result@', result);
    if (!result.destination) return; // If no valid destination, exit the function
    const { source, destination } = result;
    // Ensure there's a change in position before updating state
    const swimlane_item_Lable = result?.draggableId
      ?.includes('swimlane')
      .toString();
    const line_item_Lable = result?.draggableId
      ?.includes('line-item')
      .toString();
    console.log('result@', swimlane_item_Lable, line_item_Lable);
    if (source.index !== destination.index) {
      const reorderedItems = Array.from(swimlaneItems);
      const [removed] = reorderedItems.splice(source.index, 1);
      reorderedItems.splice(destination.index, 0, removed);
      const payload = [];
      console.log('reorderedItems@', reorderedItems);
      reorderedItems.map((item, index) => {
        console.log('item@:', item, 'index@:', index);
        payload.push({ id: item?.id, position: index });
      });
      console.log('payload@Lable', swimlane_item_Lable, line_item_Lable);
      setSwimLaneItems(reorderedItems);
      if (swimlane_item_Lable == 'true') {
        useReorderSwimlane.mutate(
          { swimlanes: payload },
          {
            onSuccess: response => {
              console.log('response@11', response);
              refetch?.();
              refetchBudgetId?.();
            },
          }
        );
      } else if (line_item_Lable == 'true') {
        useReorderLineItem.mutate(
          { line_items: payload },
          {
            onSuccess: response => {
              console.log('response@11', response);
              refetch?.();
              refetchBudgetId?.();
            },
          }
        );
      }
    }
  };

  const handleKeyDown = item => event => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        handleItemNameChange(item)(event);
        break;
      case 'Tab':
        break;
      case 'Escape':
        event.preventDefault();
        toggleEditor(item)();
        break;
      default:
        return '';
    }
  };

  const hasDetailsSheet = useMemo(() => {
    const detailSheet =
      selectedItem &&
      getDetailsSheet(
        selectedItem,
        swimlaneIndex - 1 === 0 ? 'swimlane' : type
      );
    return detailSheet?.length > 0;
  }, [selectedItem, swimlaneIndex, type]);

  return (
    <ResizableBox axis="x" width={275} height={swimlaneHeight - 40}>
      <div className="budget-swimlane">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {swimlaneItems?.map((item, index) => {
                  let counter = itemKeyRef.current.counter;
                  const itemId =
                    itemKeyRef.current.uniqueKeys[item?.id] ||
                    `${type}-${selectedItemId}-${++counter}`;
                  itemKeyRef.current.uniqueKeys[item?.id] = itemId;
                  itemKeyRef.current.counter = counter;
                  const isActive = itemId === activeItem?.rowId;

                  const itemClass =
                    index % 2 === 0 && swimlaneIndex % 2 === 0
                      ? 'swimlane-item--even'
                      : 'swimlane-item--odd';

                  const allowToAddSubCategory =
                    item?.swimlane_item_detail_sheets?.length > 0 ||
                    item?.swimlane_detail_sheets?.length > 0;

                  return (
                    <Draggable key={itemId} draggableId={itemId} index={index}>
                      {provid => (
                        <div
                          key={itemId}
                          className={`swimlane-item ${itemClass}`}
                          ref={provid.innerRef}
                          {...provid.draggableProps}
                          {...provid.dragHandleProps}
                          onClick={handleItemClick(item)}
                        >
                          {(isActive || editing[item?.id]) && (
                            <div className="swimlane-item--active" />
                          )}
                          <div className="swimlane-item__info">
                            <div
                              className="item-name-wrapper"
                              onClick={
                                isActive &&
                                !editing[item?.id] &&
                                hasEditAccess()
                                  ? toggleEditor(item)
                                  : undefined
                              }
                            >
                              {editing[item?.id] ? (
                                <span className="item-name">
                                  <FormInput
                                    defaultValue={item?.name}
                                    onBlur={handleItemNameChange(item)}
                                    onKeyDown={handleKeyDown(item)}
                                    autoFocus={true}
                                    stopOnChange={true}
                                  />
                                </span>
                              ) : (
                                <span className="item-name">{item?.name}</span>
                              )}
                            </div>
                            <div className="item-cost">
                              {formatCurrency(
                                item.cost || 0.0,
                                selectedBudget?.primary_currency
                              )}
                            </div>
                          </div>
                          {hasEditAccess() && item?.id && (
                            <div className="swimlane-item__options">
                              <DropDownMenu
                                menuOptions={lineItemOptions?.filter(
                                  lineItem =>
                                    !(
                                      lineItem.value === 'add-sub-category' &&
                                      !allowToAddSubCategory
                                    )
                                )}
                                toggleIcon={<MoreIcon className="more-icon" />}
                                onChange={handleOptionsChange(item)}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {hasEditAccess() &&
          (showAddItemInput ? (
            <div className="swimlane-item swimlane-item--add-input">
              <AddItem
                handleAddItem={handleAddItem}
                options={addItemSuggestions}
              />
            </div>
          ) : (
            <div className="swimlane-item swimlane-item--action">
              {!hasDetailsSheet && (
                <div className="action-add-item" onClick={handleAddItemClick}>
                  Add Category <AddIcon className="action-add-item__icon" />
                </div>
              )}
              {swimlaneIndex > 0 && !swimlaneItems?.length && (
                <div
                  className="action-add-details"
                  onClick={addDetailedView(swimlaneIndex - 1, selectedItem)}
                >
                  {hasDetailsSheet ? 'View' : 'Add'} Details{' '}
                  <DetailsIcon className="action-add-details__icon" />
                </div>
              )}
            </div>
          ))}
      </div>
    </ResizableBox>
  );
};

Swimlane.propTypes = {
  swimlaneHeight: PropTypes.number,
  budgetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.oneOf(['swimlane', 'line-item', 'line-item-children'])
    .isRequired,
  swimlane: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  swimlaneIndex: PropTypes.number.isRequired,
  handleAddSwimLanes: PropTypes.func.isRequired,
  addItemSuggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  addDetailedView: PropTypes.func.isRequired,
  selectedItem: PropTypes.object,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired,
};

Swimlane.defaultProps = {
  swimlaneHeight: 500,
  addItemSuggestions: null,
  swimlane: null,
  selectedItem: {},
  budgetId: '',
};
export default Swimlane;
