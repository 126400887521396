import React from 'react';
import { toast } from 'react-toastify';

import DeleteConfirmModal from 'components/DeleteConfirmModal';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { setSelectedTabInCache } from 'utils/common';
import { toastStyle } from 'utils/helper';

const DeleteBudget = () => {
  const {
    deleteBudgetMutation,
    refetchBudgets,
    refetchTabs,
    showDeleteBudget,
    hideDeleteBudgetModal,
    setSelectedBudget,
    openTabsList,
    setOpenTabsList,
    budgetData,
  } = useHomeScreenContext();

  const handleCancel = () => {
    hideDeleteBudgetModal();
  };

  const handleDeleteBudget = async () => {
    toast.loading(`Deleting budget "${budgetData?.name}" ...`);
    try {
      await deleteBudgetMutation.mutateAsync(budgetData?.id);
      const openTabs =
        openTabsList?.filter(tab => tab?.id !== budgetData?.id) || [];
      setOpenTabsList(openTabs);
      setSelectedTabInCache(openTabs[0] || {});
      setSelectedBudget(openTabs[0]);
      toast.dismiss();
      toast.success(
        `Budget "${budgetData?.name}" deleted successfully`,
        toastStyle
      );
      handleCancel();
      refetchBudgets?.();
      refetchTabs();
    } catch (error) {
      const message =
        error?.response?.data?.error ||
        error?.response?.data?.non_field_errors?.[0];
      toast.dismiss();
      toast.error(
        message ||
          `An error occurred while deleting the budget "${budgetData?.name}". Please try again later`,
        toastStyle
      );
    }
  };

  return (
    <DeleteConfirmModal
      showModal={showDeleteBudget}
      handleOnClose={handleCancel}
      onCancel={handleCancel}
      onConfirm={handleDeleteBudget}
      confirmText={`Are you sure you want to delete this budget`}
      itemName={`"${budgetData?.name}"`}
      confirmLabel="Delete"
    />
  );
};

export default DeleteBudget;
