import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import 'react-resizable/css/styles.css';

import Swimlane from '../Swimlane';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { prepareDropDownOptions } from 'utils/common';
/*
import { ReactComponent as ArrowBackIcon } from 'assets/images/arrow-back.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/images/arrow-forward.svg';
import { ReactComponent as FastRewindIcon } from 'assets/images/fast-rewind.svg';
*/
import DetailedView from '../DetailedView';

const SwimlaneLayout = ({ swimlaneHeight, budgetId }) => {
  const {
    contextualSuggestions,
    showDetailedView,
    setShowDetailedView,
    swimlanes,
    selectedBudget,
    setSwimlanes,
  } = useHomeScreenContext();
  const swimlaneBlockRef = useRef();
  const swimlaneWrapperRef = useRef();
  const closeDetailRef = useRef();
  const swimlaneDetailsWrapperRef = useRef();
  const [editing, setEditing] = useState(false);

  const [addItemSuggestions, setAddItemSuggestions] = useState([]);
  const [lastSwimlane, setLastSwimlane] = useState(null);
  const [swimlaneInfo, setSwimlaneInfo] = useState({});
  const [swimlaneNavWidth, setSwimlaneNavWidth] = useState({ width: '100%' });

  const setNavWidth = useCallback(() => {
    setTimeout(() => {
      const swimlaneWidth = swimlaneWrapperRef.current?.clientWidth || 0;
      const detailsWidth = swimlaneDetailsWrapperRef?.current?.clientWidth || 0;

      setSwimlaneNavWidth({
        swimlaneWidth: detailsWidth ? swimlaneWidth : '100%',
        detailsWidth,
        width: swimlaneWidth + detailsWidth,
        closeDetailPos:
          swimlaneBlockRef.current?.clientWidth -
          closeDetailRef.current?.clientWidth -
          10,
      });
    }, 100);
  }, []);

  useEffect(() => {
    setNavWidth();
  }, [swimlanes, setNavWidth, showDetailedView]);

  useEffect(() => {
    const setWidth = () => setNavWidth();
    window.addEventListener('resize', setWidth);

    return () => window.removeEventListener('resize', setWidth);
  }, []);

  useEffect(() => {
    setSwimlanes([{ type: 'swimlane', budgetId }]);
  }, [budgetId, setSwimlanes]);

  useEffect(() => {
    setAddItemSuggestions(
      prepareDropDownOptions(contextualSuggestions, 'name', 'id')
    );
  }, [contextualSuggestions]);

  const addDetailedView = (level, selectedItem) => () => {
    setLastSwimlane(swimlanes.pop());
    setShowDetailedView({ show: true, level, selectedItem });
  };

  const addSwimLanes = async (level, selectedItem, swimlane, hasDetails) => {
    if (level === 0) {
      setSwimlaneInfo(selectedItem);
    }
    const type = level === 0 ? 'line-item' : 'line-item-children';
    const currentSwimlanes = swimlanes.slice(0, level + 1) || [];

    if (hasDetails) {
      setSwimlanes([...currentSwimlanes]);
      setLastSwimlane({ type, selectedItem, swimlane });
      setShowDetailedView({ show: true, level, selectedItem });
    } else {
      setSwimlanes([...currentSwimlanes, { type, selectedItem, swimlane }]);
      setShowDetailedView({ show: false });
    }
  };

  const closeDetailedView = () => {
    setSwimlanes([...swimlanes, lastSwimlane]);
    setShowDetailedView({ show: false });
  };

  const handleBackClick = () => {
    swimlaneBlockRef?.current?.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleFrowardClick = () => {
    swimlaneBlockRef?.current?.scrollTo(
      swimlaneBlockRef?.current?.scrollWidth,
      0
    );
  };

  const renderSwimlaneNav = () => {
    return (
      <div
        className="budget-swimlane-nav-block"
        style={{ width: swimlaneNavWidth?.width }}
      >
        <div
          className="budget-swimlane-nav"
          style={{ width: swimlaneNavWidth?.swimlaneWidth }}
        >
          {Boolean(swimlaneInfo?.id) &&
            (!showDetailedView?.show || swimlanes?.length > 1) && (
              <div className="swimlane-name" onClick={handleBackClick}>
                {swimlaneInfo?.name}
              </div>
            )}
        </div>
        {showDetailedView?.show && (
          <div
            className="budget-swimlane-details-nav"
            style={{ width: swimlaneNavWidth?.detailsWidth }}
          >
            <div className="swimlane-name">
              Detail View: {showDetailedView?.selectedItem?.name}
            </div>
            <div
              className="close-detailed-view"
              onClick={closeDetailedView}
              ref={closeDetailRef}
              style={{ left: swimlaneNavWidth.closeDetailPos }}
            >
              Close Detail
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="budget-swimlane-block" ref={swimlaneBlockRef}>
      {renderSwimlaneNav()}
      <div className="budget-swimlane-wrapper" ref={swimlaneWrapperRef}>
        <div className="budget-swimlane-container">
          {swimlanes?.map((swimlaneItems, swimlaneIndex) => {
            return (
              <Swimlane
                key={`swimlane-${swimlaneIndex}`}
                swimlaneHeight={swimlaneHeight}
                swimlaneIndex={swimlaneIndex}
                handleAddSwimLanes={addSwimLanes}
                editing={editing}
                setEditing={setEditing}
                addDetailedView={addDetailedView}
                {...swimlaneItems}
                addItemSuggestions={
                  swimlaneIndex === 0 ? addItemSuggestions : null
                }
              />
            );
          })}
        </div>
      </div>
      {showDetailedView?.show && (
        <div
          className="budget-swimlane-details-wrapper"
          ref={swimlaneDetailsWrapperRef}
        >
          <div className="budget-swimlane-details-container">
            <DetailedView
              setNavWidth={setNavWidth}
              key={`Detail-${selectedBudget?.budget}-${showDetailedView?.selectedItem?.budget}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

SwimlaneLayout.propTypes = {
  swimlaneHeight: PropTypes.number,
  budgetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

SwimlaneLayout.defaultProps = {
  swimlaneHeight: 200,
};
export default SwimlaneLayout;
