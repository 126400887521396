import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DropDownMenu from 'components/DropDownMenu';
import Button from 'components/Button';
import Avatar from 'components/Avatar';

const CollaboratorListRow = ({
  user,
  handleShareBudget,
  isRegistered,
  permissions,
  permissionLabel,
  userRole,
  isOwner,
}) => {
  const [userPermission, setUserPermission] = useState(user?.permissions?.[0]);

  const changePermission = value => {
    setUserPermission(value);
    handleShareBudget(
      value === 'remove-access' ? 'remove_access' : 'change_permission',
      {
        ...user,
        permissions: [value],
      }
    );
  };

  const handleResend = () => {
    handleShareBudget('resend', { ...user, permissions: [userPermission] });
  };

  return (
    <div className="collaborator-list__user-row">
      {isRegistered || isOwner ? (
        <div className="user-name">
          <Avatar
            name={user?.name || user?.email}
            image={
              user?.profile_image ? (
                <img src={user?.profile_image} alt="profile-pic" />
              ) : null
            }
          />
          &nbsp;
          {user?.name || user?.email}
          {isOwner && `(You)`}
        </div>
      ) : (
        <div className="user-name">
          <div className="avatar-placeholder" />
          {user?.email}
          <Button
            label="Resend"
            className="resend-invite-btn"
            type="submit"
            onClick={handleResend}
          />
        </div>
      )}
      <div className="permissions">
        {isOwner ? (
          <span className="current-access">{userRole}</span>
        ) : (
          <DropDownMenu
            className="budget-permissions"
            menuOptions={[
              ...permissions,
              { value: 'divider' },
              { label: 'Remove Access', value: 'remove-access' },
            ]}
            toggleLabel={
              userPermission === 'remove-access'
                ? 'Remove Access'
                : permissionLabel(userPermission)
            }
            selectedOption={userPermission?.toString()}
            onChange={changePermission}
          />
        )}
      </div>
    </div>
  );
};

CollaboratorListRow.propTypes = {
  user: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.object),
  isRegistered: PropTypes.bool,
  permissionLabel: PropTypes.func,
  handleShareBudget: PropTypes.func,
  isOwner: PropTypes.bool,
  userRole: PropTypes.string,
};

CollaboratorListRow.defaultProps = {
  isOwner: false,
  isRegistered: false,
  permissionLabel: () => null,
  handleShareBudget: () => null,
  permissions: [],
  userRole: '',
};

export default CollaboratorListRow;
