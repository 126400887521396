import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Modal from 'components/Modal';
import Button from 'components/Button';
import BudgetIcon from 'assets/images/Budget.png';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { ReactComponent as FolderIcon } from 'assets/images/folder-icon.svg';
import { ReactComponent as GreaterArrow } from 'assets/images/greater-icon.svg';
import { ReactComponent as CrossIcon } from 'assets/images/cross-icon.svg';
import PropTypes from 'prop-types';
import { useBudgetQueries } from 'utils/api/queries';
import './styles.scss';
import { toastStyle } from 'utils/helper';

const MoveBudgets = ({ showMovePopup, onMoveClose, selBudgets, refetch }) => {
  const {
    refetchFolders,
    folderListing,
    moveBudgetMutation,
    moveBudgetFolderMutation,
    refetchBudgets,
  } = useHomeScreenContext();
  const [foldersData, setFoldersData] = useState(
    folderListing?.filter(
      record =>
        !selBudgets?.some(
          budget => budget?.type === 'Folder' && budget?.id === record.id
        )
    )
  );
  const [showDisabled, setDisabled] = useState(false);
  const [selId, setSelId] = useState(null);
  const [selFolder, setSelFolder] = useState([]);
  const [selectedFolder, setselectedFolder] = useState({});

  const { data, isLoading } = useBudgetQueries.useFolderByIdQuery({
    folderId: selId,
    enabled: Boolean(selId),
  });
  const folderbyIdData = data?.data;

  useEffect(() => {
    setFoldersData(
      folderbyIdData?.filter(
        record =>
          !selBudgets?.some(
            budget => budget?.type === 'Folder' && budget?.id === record.id
          )
      )
    );
  }, [folderbyIdData]);

  useEffect(() => {
    setFoldersData(
      folderListing?.filter(
        record =>
          !selBudgets?.some(
            budget => budget?.type === 'Folder' && budget?.id === record.id
          )
      )
    );
  }, [folderListing]);

  useEffect(() => {
    setFoldersData(foldersData);
  }, [foldersData]);

  useEffect(() => {
    if (selId) {
      const indexOfFolder = selFolder.findIndex(
        parentData => parentData?.id === selId
      );
      if (indexOfFolder !== -1) {
        const newArray = selFolder.slice(0, indexOfFolder + 1);
        setSelFolder(newArray);
      } else {
        // Parent folder not found, add the new folder to selFolder
        setSelFolder(prevSelected => [
          ...prevSelected,
          { id: selId, name: selectedFolder?.name },
        ]);
      }
    }
  }, [selId]);

  const handleMoveBudget = async () => {
    setDisabled(true);
    const budgetIds = [];
    const folderIds = [];
    for (let i = 0; i < selBudgets?.length; i++) {
      const id = selBudgets[i]?.id;
      if (selBudgets[i]?.type === 'Folder') {
        folderIds.push(id);
      } else {
        budgetIds.push(id);
      }
    }
    try {
      const response = await moveBudgetFolderMutation.mutateAsync({
        budget_ids: budgetIds,
        folder_ids: folderIds,
        folder: selFolder[selFolder?.length - 1]?.id || null,
      });
      const resp = response?.data;
      console.log('resp', resp);
      // if (resp) {
      // toast.success(`${resp?.name} moved successfuly`);
      toast.success(`Selected items moved successfully`, toastStyle);
      refetchFolders?.();
      // }
    } catch (error) {
      // toast.error(
      //   `An error occurred while moving ${selBudgets[i]?.name}. Please try again later`
      // );
      toast.error(
        `An error occurred while moving items. Please try again later`,
        toastStyle
      );
    }
    setDisabled(false);
    handleCancel();
  };
  const handleFolderClick = async folderdata => {
    if (folderdata.id === 'my_folders') {
      // Clear breadcrumb data
      setSelFolder([]);
      // Call initial folder listing
      setFoldersData(
        folderListing?.filter(
          record =>
            !selBudgets?.some(
              budget => budget?.type === 'Folder' && budget?.id === record.id
            )
        )
      );
      setSelId(null);
      setselectedFolder({});
    } else {
      setSelId(folderdata?.id);
      setselectedFolder({
        id: folderdata?.id,
        name: folderdata?.name,
      });
    }
  };
  useEffect(() => {
    refetchFolders();
  }, []);

  const handleCancel = () => {
    onMoveClose();
  };

  const formatDate = timestamp => {
    const date = new Date(timestamp);
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
    return formattedDate;
  };
  return (
    <Modal
      onHide={handleCancel}
      show={showMovePopup}
      size="sm"
      className="move-budget"
      bodyClass="move-budget__body"
      centered={true}
    >
      <div className="move-budget__body__header-container">
        <h1 className="move-budget__body__header">Move item(s)</h1>
        <CrossIcon className="remove-icon" onClick={handleCancel} />
      </div>
      <div className="selected-budgets">
        {selBudgets?.length} {selBudgets?.length <= 1 ? 'item' : 'items'}{' '}
        selected
      </div>
      <div className="selected-budgets-list mt-2">
        {selBudgets?.map((item, index) => (
          <div className="budgets-name-wrapper" key={index}>
            <div className="budget-name me-2">
              {item?.type === 'Folder' ? (
                <FolderIcon />
              ) : (
                <img src={BudgetIcon} alt="Budget" />
              )}{' '}
              {item?.full_name}
            </div>
          </div>
        ))}
      </div>
      <div className="selected-budget-message">
        This is to confirm that the selected item(s) will be moved to{' '}
        <span className="selected-folder">
          {selectedFolder?.name || 'My Budgets'}
        </span>
      </div>
      {selFolder?.length === 0 ? (
        <div className="folder-main-head">My Budgets</div>
      ) : (
        <div className="folder-head-wrapper mt-4">
          <nav
            style={{ '--bs-breadcrumb-divider': '>' }}
            aria-label="breadcrumb"
          >
            <ol className="breadcrumb">
              <li
                className="breadcrumb-item d-flex align-items-center folder-path"
                onClick={() => {
                  handleFolderClick({
                    id: 'my_folders',
                    name: 'my_folders',
                  });
                }}
              >
                <span className="folder-head">My Budgets</span>
                <GreaterArrow />
              </li>
              {selFolder?.map((folder, index) => (
                <div
                  className="folder-path d-flex align-items-center"
                  key={index}
                  onClick={() => !showDisabled && handleFolderClick(folder)}
                >
                  <li key={folder.id} className="breadcrumb-item">
                    <span className="folder-head">{folder.name}</span>
                  </li>
                  {index < selFolder.length - 1 && <GreaterArrow />}
                </div>
              ))}
            </ol>
          </nav>
        </div>
      )}
      <div className="folders-listing">
        {foldersData?.map((item, index) => (
          <div className="folder-item-wrapper" key={item?.id}>
            <div
              className="folder-name-wrapper"
              onClick={() => !showDisabled && handleFolderClick(item)}
            >
              <FolderIcon />
              <div className="folder-item">{item?.name}</div>
            </div>
            <div className="folder-created-date">
              {formatDate(item?.created_on)}
            </div>
          </div>
        ))}
      </div>
      <div className="move-here-btn">
        <Button
          label={'Move Here'}
          className="move-budget__action__confirm"
          onClick={() => handleMoveBudget()}
          isDisabled={showDisabled}
        />
      </div>
    </Modal>
  );
};

MoveBudgets.propTypes = {
  showMovePopup: PropTypes.bool,
  onMoveClose: PropTypes.func,
  selBudgets: PropTypes.array,
  refetch: PropTypes.func,
};

MoveBudgets.defaultProps = {
  showMovePopup: false,
  onMoveClose: () => null,
  selBudgets: [],
  refetch: () => null,
};

export default MoveBudgets;
